<jqxWindow #viewCalendar [theme]="theme" [position]="getFormPos(viewCalendar, 0)" [width]="910" [height]="480" [zIndex]="998"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <jqxScheduler #schedulerReference [theme]="theme" [width]="'100%'" [height]="'calc(100% - 40px)'" [views]="views" [view]="2"
      [showLegend]="true" [date]="date" [localization]="langGrid" [appointmentDataFields]="appointmentDataFields"
      [source]="dataAdapter" [resources]="resources" [editDialog]="false" (onViewChange)="ViewChange($event)">
    </jqxScheduler>

    <div style="margin-left: 10px; display: flex; align-items: center; margin-top: 10px;">
      <jqxButton [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
