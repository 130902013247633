<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [width]="1000" [height]="450" [zIndex]="999"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Listado_tarjetas')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div style="float: left; height: '100%'; width: '100%';">
      <div>
        <app-header #header [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
          [seeCheckTarjVirtual]="true" (eventChangeTipoTarjeta)="onCheckTarjVirtual($event)"
          (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()"
          (eventExportar)="onExportar()" (eventBuscador)="onBuscar()">
        </app-header>
      </div>

      <div style="float:left; margin-top: 5px; width: 100%; height: calc(100% - 85px);">
        <jqxGrid #gridTarjetas [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="adapterTarjetas"
          [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columnsTarjetas"
          [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
          [showstatusbar]="true" [rowsheight]="20" [groupable]="true" [columnsheight]="20" [enabletooltips]="false"
          [groupsrenderer]="groupsrenderer" [editable]="false" [statusbarheight]="0" [filter]="filter" (onFilter)="onFilter($event)">
        </jqxGrid>
      </div>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
