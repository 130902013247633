<div class="loader-container" *ngIf="!columns.length">
  <div class=" loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
  [position]="getFormPos(form, 0)" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_volumetricos')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <app-header #header [exportar]="true" [periodo]="true" [volumetricos]="true" [filtro]="true" [resetFiltro]="true"
      [buscador]="true" [noLibre]="false" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
      (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
    </app-header>

    <div style="float:left; margin-top: 6px; width: 100%; height: calc(100% - 90px);">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
        [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [filterable]="false" [showstatusbar]="true"
        [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
        [groupable]="true" [showtoolbar]="true" [rendertoolbar]="createToolBar"
        (onBindingcomplete)="onBindingComplete()" (onRowclick)="onRowClick($event)">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 8px; width: 100%;">
      <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
        [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
        (onClick)="onCentrar($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='90' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/grafica.png"'
        [value]="translate('Grafico')" (onClick)="onGrafica($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='150' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/resumen.png"'
        [value]="translate('Resumen') + ' ' + translate('Periodo').toLowerCase()" (onClick)="onResumen($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='80' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/total.png"'
        [value]="translate('Total')" (onClick)="onTotal($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>