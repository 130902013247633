import {
  Input,
  OnInit,
  Output,
  ViewChild,
  Component,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { ElemImageModel } from 'src/app/services/elements/models/elem-image-model';
import { ElemInstalacionDetalleImagenModel } from 'src/app/services/elements/models/instalacion/elem-instalacion-detalle-imagen.model';
import { ElemInstalacionDetalleNotifImagenModel } from 'src/app/services/elements/models/instalacion/elem-instalacion-detalle-notif-imagen.model';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  @ViewChild('imgPrincipalContainer') imgPrincipalContainer;
  @ViewChild('uploadImageWind') uploadImageWind: jqxWindowComponent;

  @Input() imagenes: any;
  @Input() asociateId: number;
  @Input() from: string; // Elemento, detalle o notificacion

  @Output() onAddImage: EventEmitter<any> = new EventEmitter;
  @Output() onRmvImage: EventEmitter<any> = new EventEmitter;

  public environment = environment;

  public inputFile: any = null;
  public imgToUpload;

  public selectedImg: any;
  private imgPrincipalRotation = 0;

  constructor(
    private elemService: ElementsService,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {}

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'from':
            this.from = changes[propName].currentValue;

            if(this.from === 'elemento') {
              this.imgToUpload = new ElemImageModel()
            }else if(this.from === 'detalle') {
              this.imgToUpload = new ElemInstalacionDetalleImagenModel()
            }else if(this.from === 'notificacion') {
              this.imgToUpload = new ElemInstalacionDetalleNotifImagenModel()
            }
            break;
          case 'imagenes':
            this.imagenes = changes[propName].currentValue;
            this.selectedImg = null;
            if(this.from === 'elemento') {
              if(this.imagenes?.length > 0) {
                this.selectedImg = this.imagenes[0];
              }
            }else if(this.from === 'detalle' || this.from === 'notificacion') {
              if(this.imagenes?.length > 0) {
                this.selectedImg = this.imagenes[0];
              }
            }
            this.imgPrincipalRotation = 0;
            break;
          case 'asociateId':
            this.asociateId = changes[propName].currentValue;
            break;
          default:
            break;
        }
      }
    }
  }

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  /* Establece la imagen principal al
    hacer click en una imagen del slider */
  setImgPrincipal(img) {
    this.selectedImg = img;
    this.rotate('0');
  }

  /* Selecciona, a traves de las flechas,
   la imagen siguiente o anterior */
  changeImage(direction: string) {
    let index;
    switch (direction) {
      case 'previous':
        if(this.from === 'elemento') {
          index = this.imagenes?.indexOf(this.selectedImg);
          if (index > 0) {
            this.rotate('0');
            this.selectedImg = this.imagenes[index - 1];
          } else {
            this.rotate('0');
            this.selectedImg = this.imagenes[this.imagenes.length - 1];
          }
        }else if(this.from === 'detalle' || this.from === 'notificacion') {
          index = this.imagenes.indexOf(this.selectedImg);
          if (index > 0) {
            this.rotate('0');
            this.selectedImg = this.imagenes[index - 1];
          } else {
            this.rotate('0');
            this.selectedImg = this.imagenes[this.imagenes.length - 1];
          }
        }
        break;
      case 'next':
        if(this.from === 'elemento') {
          index = this.imagenes.indexOf(this.selectedImg);
          if (index < this.imagenes.length - 1) {
            this.rotate('0');
            this.selectedImg = this.imagenes[index + 1];
          } else {
            this.rotate('0');
            this.selectedImg = this.imagenes[0];
          }
        }else if(this.from === 'detalle' || this.from === 'notificacion') {
          index = this.imagenes.indexOf(this.selectedImg);
          if (index < this.imagenes.length - 1) {
            this.rotate('0');
            this.selectedImg = this.imagenes[index + 1];
          } else {
            this.rotate('0');
            this.selectedImg = this.imagenes[0];
          }
        }
        break;
      default:
        break;
    }
  }

  // Rota la imagen principal
  rotate(orientation: string) {
    if (this.imgPrincipalContainer) {
      switch (orientation) {
        case 'left':
          this.imgPrincipalRotation = this.imgPrincipalRotation - 90;
          this.imgPrincipalContainer.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case 'right':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 90;
          this.imgPrincipalContainer.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '180':
          this.imgPrincipalRotation = this.imgPrincipalRotation + 180;
          this.imgPrincipalContainer.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        case '0':
          this.imgPrincipalRotation = 0;
          this.imgPrincipalContainer.nativeElement.style.transform = "rotate(" + this.imgPrincipalRotation + "deg)"
          break;
        default:
          break;
      }
    }
  }

  // Abre la ventana de subir imagen
  openUploadImageWind() {
    this.uploadImageWind.open();
  }

  // Cierra la ventana de subir imagen
  closeUploadImageWind() {
    this.uploadImageWind.close();
  }

  // Cuando se cierra la ventana de subir imagen
  onCloseWindowImage() {
    this.inputFile = null

    if(this.from === 'elemento') {
      this.imgToUpload.imagen = null;
    }else if(this.from === 'detalle' || this.from === 'notificacion') {
      this.imgToUpload.Imagen = null;
    }
  }

  // Selecciona la imagen a subir
  onSelectImage(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if(this.from === 'elemento') {
        this.imgToUpload.imagen = reader.result.toString().slice(reader.result.toString().indexOf(',') + 1);
      }else if(this.from === 'detalle' || this.from === 'notificacion') {
        this.imgToUpload.Imagen = reader.result.toString().slice(reader.result.toString().indexOf(',') + 1);
      }
    };
  }

  // Sube la imagen al servidor
  async uploadImage() {
    if (this.imgToUpload.imagen || this.imgToUpload.Imagen) {
      if(this.from === 'elemento') {
        this.imgToUpload.idElemento = this.asociateId;
        this.imgToUpload.fecha = new Date();
        const response = await this.elemService.uploadImage(this.imgToUpload);
        if (response) {
          response.fecha = new Date(response.fecha);
          response.fecha.setTime((response.fecha.getTime() + 2 * 60 * 60 * 1000));
          this.imagenes.push(response);
          this.selectedImg = this.imagenes[this.imagenes.length - 1];
          this.closeUploadImageWind();
        } else {
          MainComponent.getInstance().showWarning(
            'Error',
            'Error_api',
            2000
          );
        }
      }else if(this.from === 'detalle' || this.from === 'notificacion') {
        if(this.from === 'detalle'){
          this.imgToUpload.IdInstalacionDetalle = this.asociateId;
        }else {
          this.imgToUpload.IdInstalacionDetalleNotificacion = this.asociateId;
        }

        this.imgToUpload.Fecha = new Date();

        this.onAddImage.emit({...this.imgToUpload});

        this.selectedImg = this.imagenes[this.imagenes.length - 1];
        this.closeUploadImageWind();
      }
    } else {
      MainComponent.getInstance().showWarning(
        'ATENCION',
        'Selecciona_imagen',
        2000
      );
    }
  }

  // Abre la ventana de borrar imagen
  openDeleteImageWind() {
    this.modal.confirm({
      nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
      nzContent: this.translate('Quiere_borrar_imagen'),
      nzCentered: true,
      nzCancelText: this.translate('CANCELAR'),
      nzOkText: this.translate('SI'),
      nzOnOk: async () => {
        this.removeImageElement();
      }
    });
  }

  // Borra la imagen del servidor
  async removeImageElement() {
    if(this.from === 'elemento') {
      if (await this.elemService.removeImageElement(this.selectedImg)) {
        this.imagenes.splice(this.imagenes.indexOf({...this.selectedImg}), 1);

        if (this.imagenes?.length > 0) {
          this.selectedImg = this.imagenes[0];
        } else {
          this.selectedImg = null;
        }

        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_borrado',
          2000
        );
      } else {
        MainComponent.getInstance().showWarning(
          'Error',
          'Error_api',
          2000
        );
      }
    }else if(this.from === 'detalle' || this.from === 'notificacion') {
      this.onRmvImage.emit(this.selectedImg)

      if (this.imagenes?.length > 0) {
        this.selectedImg = this.imagenes[0];
      } else {
        this.selectedImg = null;
      }

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    }
  }
}
