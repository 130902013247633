import { ElemInstalacionDetalleModel } from "./elem-instalacion-detalle.model";
import { ElemInstalacionEstadoModel } from "./elem-instalacion-estado.model";
import { ElemInstalacionNuevaInstalacion } from "./elem-instalacion-nueva-instalacion.model";

export class ElemInstalacionModel {
  Detalle: ElemInstalacionDetalleModel[] = [];
  Empresa: number = 0;
  Estado: ElemInstalacionEstadoModel = new ElemInstalacionEstadoModel();
  FechaBajar: string = '';
  FechaCreacion: string = '';
  FechaModificacion: string = '';
  Guid: string = '';
  Id: number = 0;
  IdElemento: number = 0;
  IdEstado: number = 0;
  NuevaInstalacion: ElemInstalacionNuevaInstalacion = new ElemInstalacionNuevaInstalacion();
  Observaciones: string = '';
  RequiereInstalacionCerradura: Boolean = false;
  RequiereInstalacionIdentificador: Boolean = false;
  RequiereInstalacionTag: Boolean = false;
  RequiereInstalacionVolumetrico: Boolean = false;
}
