<jqxRibbon style="overflow: hidden" [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
[position]="'top'">
  <ul style="width: 100%; text-align: left">
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Volumetrico") }}
    </li>
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Tag") }}
    </li>
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Ecolock") }}
    </li>
  </ul>
  <div>
    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionVolumetrico">
        <fieldset>
          <legend>{{ translate('Volumetrico') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit ? elemEdit?.Instalacion?.RequiereInstalacionVolumetrico : false" [disabled]="!editVolumetrico">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionVolum [ngModel]="getDetalleInstalacion(2)?.IdEstado" (focusout)="dropEstadoInstalacionVolum.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion" [disabled]="!editVolumetrico">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(2)?.Observaciones" (ngModelChange)="getDetalleInstalacion(2).Observaciones = $event" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="volumImages" [from]="'detalle'"
            [imagenes]="getDetalleInstalacion(2)?.Imagenes" [asociateId]="getDetalleInstalacion(2)?.Id"
            (onAddImage)="addImageDetalle($event, 2)" (onRmvImage)="rmvImageDetalle($event, 2)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasVolum [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasVolum"
            [columns]="columnsIncidenciasVolum" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifVolumImages" [from]="'notificacion'"
            [imagenes]="incdVolumSelec?.Imagenes" [asociateId]="incdVolumSelec?.Id"
            (onAddImage)="addImageNotificacion($event, incdVolumSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdVolumSelec)">
          </app-images>
        </fieldset>
      </div>
    </div>

    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionTag">
        <fieldset>
          <legend>{{ translate('Tag') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.RequiereInstalacionTag : false" [disabled]="!editTag">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionTag [ngModel]="getDetalleInstalacion(1)?.IdEstado" (focusout)="dropEstadoInstalacionTag.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion" [disabled]="!editTag">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(1)?.Observaciones" (ngModelChange)="getDetalleInstalacion(1).Observaciones" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="tagImages" [from]="'detalle'"
            [imagenes]="getDetalleInstalacion(1)?.Imagenes" [asociateId]="getDetalleInstalacion(1)?.Id"
            (onAddImage)="addImageDetalle($event, 1)" (onRmvImage)="rmvImageDetalle($event, 1)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasTag [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasTag"
            [columns]="columnsIncidenciasTag" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifTagImages" [from]="'notificacion'"
            [imagenes]="incdTagSelec?.Imagenes" [asociateId]="incdTagSelec?.Id"
            (onAddImage)="addImageNotificacion($event, incdTagSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdTagSelec)"
          >
          </app-images>
        </fieldset>
      </div>
    </div>

    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionEcoLock">
        <fieldset>
          <legend>{{ translate('Ecolock') }}</legend>
          <div style="margin-top: 3%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Requiere_instalacion") }}:
            </div>
            <div style="margin-top: 5px; margin-left: 10px;">
              <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.RequiereInstalacionCerradura : false" [disabled]="!editEcoLock">
                {{translate('Ecolock')}}
              </jqxCheckBox>
            </div>
            <div style="margin-top: 5px; margin-left: 10px;">
              <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.RequiereInstalacionIdentificador : false" [disabled]="!editEcoLock">
                {{translate('Identificacion')}}
              </jqxCheckBox>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>
            <jqxDropDownList #dropEstadoInstalacionEcolock [ngModel]="getDetalleInstalacion(3)?.IdEstado" (focusout)="dropEstadoInstalacionEcolock.close()"
              [width]="'100%'" [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion" [disabled]="!editEcoLock">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Tipo_dispositivo") }}
            </div>
            <jqxDropDownList #dropTipoDispositivoEcolock [ngModel]="elemEdit?.TipoDispositivoEcolock" (focusout)="dropTipoDispositivoEcolock.close()"
              [width]="'100%'" [dropDownHeight]="30" [source]="sourceTipoDispositivoEcolock" [disabled]="!editEcoLock">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(3)?.Observaciones" (ngModelChange)="getDetalleInstalacion(3).Observaciones = $event" [width]="'100%'" [height]="100">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="ecolockImages" [from]="'detalle'"
            [imagenes]="getDetalleInstalacion(3)?.Imagenes" [asociateId]="getDetalleInstalacion(3)?.Id"
            (onAddImage)="addImageDetalle($event, 3)" (onRmvImage)="rmvImageDetalle($event, 3)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasEcolock [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterIncidenciasEcolock"
            [columns]="columnsIncidenciasEcolock" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifEcolockImages" [from]="'notificacion'"
            [imagenes]="incdEcolockSelec?.Imagenes" [asociateId]="incdEcolockSelec?.Id"
            (onAddImage)="addImageNotificacion($event, incdEcolockSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdEcolockSelec)">
          </app-images>
        </fieldset>
      </div>
    </div>
  </div>
</jqxRibbon>
