import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { TicketModel } from 'src/app/services/sso/models/ticket.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';

import { CiudadanosComponent } from '../ciudadanos.component';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { SelectorCatastroComponent } from '../direccion/selector-catastro/selector-catastro.component';
import { CatastroService } from 'src/app/services/direccion/catastro.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { CiudadanosTarjetasComponent } from '../ciudadanos-tarjetas/ciudadanos-tarjetas.component';
import { DireccionListadoComponent } from '../direccion/direccion-listado/direccion-listado.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-ciudadanos-edit',
  templateUrl: './ciudadanos-edit.component.html',
  styleUrls: ['./ciudadanos-edit.component.css']
})
export class CiudadanosEditComponent extends CustomForms implements OnInit {
  // Componentes del html
  @ViewChild('windowCiudadanosForm') windowCiudadanosForm: jqxWindowComponent;
  @ViewChild('ciudRibbon') ciudRibbon: jqxRibbonComponent;
  @ViewChild('gridDirecciones') gridDirecciones: jqxGridComponent;
  @ViewChild('mapSearch') mapSearch: any;
  @ViewChild('dropValidado') dropValidado: jqxDropDownListComponent;
  @ViewChild('selectorCatastro') selectorCatastro: SelectorCatastroComponent;

  @ViewChild('ciudadanoTarjetas') ciudadanoTarjetas: CiudadanosTarjetasComponent;
  @ViewChild('ciudadanoDirecciones') ciudadanoDirecciones: DireccionListadoComponent;
  inputMapSearch: HTMLInputElement;

  // Funcionalidad
  public static _this: CiudadanosEditComponent;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  //Comprobaciones
  showLoader: boolean = false;
  public guardar = false;
  ciudadanoEmailExist = false;
  ciudadanoDniExist = false;
  public ecoIdentificadores = false;
  public ecoCompostaje = false;

  // Centrar mapa y añadir marca
  public map: MapComponent;
  public marker: MapMarker;

  // Tamaño de la pantalla sin contar menu gestion y lateral
  mapHeight;
  mapWidth;

  // Ciudadano editandose
  public ciudadano: CiudadanoModel;

  // Generales
  protected direccion: DireccionModel;
  public rol;
  public source: string[] =
    [
      AppComponent.translate('Pendiente'),
      AppComponent.translate('Validado'),
      AppComponent.translate('Rechazado')
    ]

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ciudadanoService: CiudadanosService,
    private ssoService: SsoService,
    private modal: NzModalService,
    private catastroService: CatastroService,
    private zonesService: ZonesService,
  ) {
    super();
    CiudadanosEditComponent._this = this;
  }

  ngOnInit(): void {
    let ticketSso: TicketModel = JSON.parse(sessionStorage.getItem('ticketSSO'));

    // Comprueba si tiene el modulo ecoidentificadores
    if(ticketSso && ticketSso.Modulos) {
      ticketSso.Modulos.forEach(element => {
        if (element.Id === 4) {
          this.ecoIdentificadores = true;
        }
      });
    }

    // Comprueba si tiene la aplicacion de ecoCompostaje
    if(ticketSso && ticketSso.Aplicacion) {
      if (ticketSso.Aplicacion.Id == 4) {
        this.ecoCompostaje = true;
      }
    }

    // Instancia el mapa
    this.map = MainComponent.getInstance().getMap();

    // Obtiene alto y ancho de pantalla sin contar menu inferior y lateral
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    // Obtiene idioma
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    this.guardar = false;
  }

  public async init(componentRef: any, ciudadano: CiudadanoModel) {
    // Inicializa el componente
    this.componentRef = componentRef;

    // Obtiene el rol del usuario logueado
    this.rol = this.ssoService.getTicket().Rol;

    // Obtiene el ciudadano para modificar
    this.ciudadano = ciudadano ? ciudadano : new CiudadanoModel;

    // Si tiene ciudadano establece el valor para el campo de validacion
    if (ciudadano) {
      /*
        Los valores finales seran 14, 15 y 16,
        se usan 0, 1 y 2 porque se han introducido datos a mano
      */
      switch (ciudadano.estadoValidacion) {
        case 14 || 0:
          ciudadano.validadoTexto = this.translate('Pendiente');
          break;
        case 15 || 1:
          ciudadano.validadoTexto = this.translate('Aceptado');
          break;
        case 16 || 2:
          ciudadano.validadoTexto = this.translate('Rechazado');
          break;
        default:
          break;
      }

      // Inicia el array de tarjetas, si no existe
      if (!this.ciudadano.tarjetas) {
        this.ciudadano.tarjetas = [];
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.windowCiudadanosForm);

    this.disableTabs(true);

    setTimeout(() => {
      this.inputMapSearch = this.mapSearch.nativeElement.children[0].children[0].children[0].children[0];

      if (this.dropValidado) {
        if (this.ciudadano.id !== 0) {
          switch (this.ciudadano.estadoValidacion) {
            case 14 || 0:
              this.dropValidado.selectIndex(0);
              break;
            case 15 || 1:
              this.dropValidado.selectIndex(1);
              break;
            case 16 || 2:
              this.dropValidado.selectIndex(2);
              break;
            default:
              break;
          }
        } else {
          this.dropValidado.selectIndex(0);
        }
      }
    }, 500)
  }

  disableTabs(selectAt:boolean = false) {
    if(this.ciudadano && this.ciudadano.id === 0) {
      this.ciudRibbon.disableAt(1);
      this.ciudRibbon.disableAt(2);
    }else if((this.ciudadano.estadoValidacion !== 15 && this.ciudadano.estadoValidacion !== 1) || this.ciudadano.direcciones.length === 0) {
      this.ciudRibbon.disableAt(2);
    }else if(selectAt) {
      this.ciudRibbon.selectAt(1);
      this.ciudRibbon.selectAt(2);
      this.ciudRibbon.selectAt(0);
    }
  }

  enableTabs() {
    if((this.ciudadano.estadoValidacion === 15 || this.ciudadano.estadoValidacion === 1) && this.ciudadano.direcciones.length > 0){
      this.ciudRibbon.enableAt(2);
    }
  }

  tabSelected(event: any) {
    if(event?.args?.selectedIndex === 1) {
      this.ciudadanoDirecciones.refreshGrid();
    }

    if(event?.args?.selectedIndex === 2) {
      this.ciudadanoTarjetas.cargarGrids();
    }
  }

  // Elimina el marcador del mapa
  removeMarker() {
    if (this.marker) {
      this.map.removeMarker(this.marker);
    }
  }

  closeWindow(){
    this.modal.confirm({
      nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
      nzContent: this.translate('No_ha_guardado'),
      nzCentered: true,
      nzCancelText: this.translate('CANCELAR'),
      nzOkText: this.translate('SI'),
      nzOnOk: async () => {
        this.modal.closeAll();
        this.windowCiudadanosForm.close();
      }
    });
  }

  public onClose() {
    CiudadanosComponent._this.getCiudadanos();
    this.removeMarker();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /*
    Comprueba el email del ciudadano,
    si existe se muestra un mensaje de error
  */
  async checkCiudadano(event: any) {
    if(this.ciudadano.id === 0) {
      if (await this.ciudadanoService.checkCiudadanoExist(event.target.value)) {
        this.ciudadanoEmailExist = true;
      } else {
        this.ciudadanoEmailExist = false;
      }
    }else {
      this.ciudadanoEmailExist = false;
    }
  }

  onMapSearch(event: any) {
    this.ciudadano.direccion = event.place.Street;
    this.ciudadano.codigoPostal = event.place.PostalCode;
    this.ciudadano.poblacion = event.place.District;
    this.ciudadano.municipio = event.place.Locality;
    this.ciudadano.provincia = event.place.AdministrativeArea2;
    this.ciudadano.comunidadAutonoma = event.place.AdministrativeArea1;
    this.ciudadano.numero = event.place.StreetNumber;

    this.inputMapSearch.value = '';
  }

  private subscriptionMapClick: any = null;

  onPosicionar() {
    this.windowCiudadanosForm.collapse();
    // Cambio el puntero del ratón sobre el mapa
    this.map.setMousePointer('assets/images/posicionar.png');
    if (!this.subscriptionMapClick) {
      this.subscriptionMapClick = this.subscribeOnMapClick();
    }
  }

  // Cada vez que se pincha sobre la cartogafía
  subscribeOnMapClick(): any {
    return this.map.subscribeOnMapClick(
      this,
      async (_this: any, position: MapLatLng) => {
        let direccion = await this.zonesService.getDireccion(position.lat, position.lng);
        this.ciudadano.direccion = direccion.Street;
        this.ciudadano.numero = direccion.StreetNumber;
        this.ciudadano.codigoPostal = direccion.PostalCode;
        this.ciudadano.poblacion = direccion.District;
        this.ciudadano.municipio = direccion.Locality;
        this.ciudadano.provincia = direccion.AdministrativeArea2;
        this.ciudadano.comunidadAutonoma = direccion.AdministrativeArea1;
        this.subscriptionMapClick.unsubscribe();
        this.subscriptionMapClick = null;
        this.map.setMousePointer('');
        try {
          this.windowCiudadanosForm?.expand();
        } catch (error) {
        }
      }
    );
  }

  changeValidado(event: any) {
    let newValue = event.args.item.value;

    if (newValue === AppComponent.translate('Pendiente')) {
      this.ciudadano.estadoValidacion = 14;
    } else if (newValue === AppComponent.translate('Validado')) {
      this.ciudadano.estadoValidacion = 15;
    } else if (newValue === AppComponent.translate('Rechazado')) {
      this.ciudadano.estadoValidacion = 16;
    }

    this.disableTabs();
    this.enableTabs();
  }

  asignarDireccion(event: DireccionModel) {
    this.direccion = null;
    this.direccion = event;

    this.ciudRibbon.selectAt(2);
  }

  async saveCatastro(){
    this.selectorCatastro.catastro.referenciaCatastral = this.selectorCatastro.referenciaCatastral.host[0].value;
    try {
      await this.catastroService.saveCatastro(this.selectorCatastro.catastro);
    } catch (error) {

    }
  }

  async onGuardar(): Promise<void> {
    this.showLoader = true;
    if(this.ciudadano?.direccion !== null && !this.ciudadanoEmailExist) {
      if(this.selectorCatastro && this.selectorCatastro.catastro &&
        this.selectorCatastro.catastro.titular !== '' &&
        this.selectorCatastro.catastro.referenciaCatastral !== ''
      ) {
        this.saveCatastro();
      }

      let ciudadanoOriginal = {...this.ciudadano};

      this.ciudadanoService.saveCiudadano(this.ciudadano).then(
        async (result: CiudadanoModel | any) => {
          if(result?.id){
            if(ciudadanoOriginal.id === 0){
              this.ciudadanoService.getCiudadano(result?.id).then(
                async (res: CiudadanoModel) => {
                  if(res) {
                    if(ciudadanoOriginal.id === 0) {
                      this.ciudadano = res;
                      this.ciudadanoDniExist = false;

                      if(!this.ciudadano.direcciones) {
                        this.ciudadano.direcciones = [];
                      }

                      switch (this.ciudadano.estadoValidacion) {
                        case 14 || 0:
                          this.ciudadano.validadoTexto = this.translate('Pendiente');
                          break;
                        case 15 || 1:
                          this.ciudadano.validadoTexto = this.translate('Aceptado');
                          break;
                        case 16 || 2:
                          this.ciudadano.validadoTexto = this.translate('Rechazado');
                          break;
                        default:
                          break;
                      }

                      this.ciudRibbon.enableAt(1);
                      this.enableTabs();
                    }
                    this.showLoader = false;

                    MainComponent.getInstance().showInfo(
                      'ATENCION',
                      'Registro_almacenado',
                      2000
                    );
                  }else {
                    this.showLoader = false;
                  }
                }
              )
            }else{
              this.guardar = true;
              setTimeout(() => {
                this.removeMarker();
                this.ciudadanoTarjetas.onGuardar().then(
                  (result) => {
                    this.windowCiudadanosForm.close();
                    this.showLoader = false;

                    MainComponent.getInstance().showInfo(
                      'ATENCION',
                      'Registro_almacenado',
                      2000
                    );
                  }
                );
              }, 300)
            }
          }else {
            this.showLoader = false;
            if(result?.error?.message === "El ciudadano ya existe") {
              this.ciudadanoDniExist = true;
            }else {
              MainComponent.getInstance().showError(
                'ATENCION',
                'Revisa_campos_formulario',
                2000
              );
            }
          }
        }
      );
    }else {
      this.showLoader = false;
      MainComponent.getInstance().showError(
        'ATENCION',
        'Revisa_campos_formulario',
        2000
      );
    }
  }
}
