<jqxWindow #windowJob [theme]="environment.tema" [position]="getFormPos(windowJob, 0)" [width]="mapWidth"
  [height]="mapHeight" [zIndex]="999" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onExpand)="onExpand()" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Jobs')}}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <div style="float: left; width: 100%; height: 100%;">
      <div>
        <app-header #header [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
          (eventExportar)="onExportar()" (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()"
          (eventResetFilter)="eventResetFilter()">
        </app-header>
      </div>
      <jqxGrid #gridJobs [theme]="environment.tema" [height]="'calc(100% - 70px)'" [width]="'100%'" [source]="adapterJobs"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
        [columns]="columnsJobs" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false"
        [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
        [rowsheight]="25" [columnsheight]="30" [enabletooltips]="false" [groupable]="true"
        [groupsrenderer]="groupsrenderer" [editable]="editable" (onGroupschanged)="resizeColumns()"
        (onCellclick)="onCellClick($event)" (onRowdoubleclick)="onRowdoubleclick($event)"
        (onCellvaluechanged)="onCellvaluechanged($event)" (onFilter)="onFilter($event)">
      </jqxGrid>
      <div>
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formJob></div>
