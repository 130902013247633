import { AppComponent } from "src/app/app.component";
import { MapBounds, MapComponent, MapLatLng, MapMarker, MapPolygon, MapPolyline } from "src/app/imports";
import { ConfigService } from "src/app/services/config/config.service";
import { PosicionModel } from "src/app/services/positions/models/posicion.model";
import { ResourcesService } from "src/app/services/resources/resources.service";
import { SensorDatosModel } from "src/app/services/sensors/models/sensor-datos.model";
import { SensorsService } from "src/app/services/sensors/sensors.service";
import { DateUtils } from "src/app/utils/date-utils";
import { NumberUtils } from "src/app/utils/number-utils";
import { MainComponent } from "../main/main.component";
import { TracksComponent } from "./tracks.component";
import { GeoUtils } from 'src/app/utils/geo-utils';
import { Config } from "./track-config";

export class Track {
    private posiciones: PosicionModel[];
    private sensores: SensorDatosModel[];
    private sensoresSelec = null;
    private polylines: MapPolyline[] = [];
    private map: MapComponent;
    private markers: MapMarker[] = [];
    private polygons: MapPolygon[] = [];
    private markerMovil: MapMarker;
    private indexS2S = 0;
    private paused = false;
    private timerS2S = null;
    private intervalS2S = 1000;
    private colorFix = false;
    private oldPos: PosicionModel = null;
    private polyline: MapPolyline = null;
    private color = '';
    private oldColor = '';
    private sensorIndex = 0;
    private sensorIndexColor = 0;
    private sensorColorEstado = NaN;
    private config: Config = new Config();
    private oldPosDirection = new MapLatLng(0, 0);

    constructor(posiciones: PosicionModel[], sensores: SensorDatosModel[], sensoresSelec: any,
        private sensorService: SensorsService,
        private resourcesService: ResourcesService,
        private configService: ConfigService,) {
        this.posiciones = posiciones;
        this.sensores = sensores;
        this.sensoresSelec = sensoresSelec;
        this.map = MainComponent.getInstance().getActiveMap();
    }

    async getConfiguration(): Promise<void> {
        const res = await this.configService.getUsuEmpApp('config-recorridos', null);
        if (res) {
            this.config = JSON.parse(res);
        }
        this.config.tiempoParada *= 60000; // En milisegundos
    }

    // Reproduce el recorrido de un tiron
    async playTotal(color: string): Promise<void> {
        this.clear();
        await this.getConfiguration();
        this.colorFix = color ? true : false;
        this.oldPos = null;
        this.polyline = null;
        this.oldColor = '';
        this.oldPosDirection = new MapLatLng(0, 0);
        const swCorner = new MapLatLng(90, 180);
        const neCorner = new MapLatLng(-90, -180);
        // Creo el icono para el móvil
        this.setMarkerMovil(this.posiciones[0]);
        // Pongo el marcador de inicio de recorrido
        this.setInicio(this.posiciones[0]);
        // Recorro las posiciones y pinto el recorrido
        this.posiciones.forEach(pos => {
            // Si el color no es fijo cojo el color en función de lo configurado
            if (!this.colorFix) {
                color = this.getColor(pos.Velocidad, pos.Fecha);
            }
            // Si cambia el color creo una nueva polilinea
            if (color !== this.oldColor || !this.polyline) {
                if (this.polyline) {
                    this.map.addPolylinePoint(this.polyline, {
                        dataModel: pos,
                        content: DateUtils.formatDateTimeShort(pos.Fecha, true) + '<br>' + pos.Velocidad + ' km/h',
                        position: new MapLatLng(pos.Lat, pos.Lng)
                    });
                }
                this.polyline = this.addPolyline(color);
                this.oldColor = color;
            }
            // Añado puntos a la polilinea
            this.map.addPolylinePoint(this.polyline, {
                dataModel: pos,
                content: this.resourcesService.getMovil(pos.MovilId).Nombre + '<hr>' +
                    DateUtils.formatDateTimeShort(pos.Fecha, true) + '<br>' +
                    pos.Velocidad + ' km/h',
                position: new MapLatLng(pos.Lat, pos.Lng)
            });
            // Calculo el tiempo que ha pasado respecto a al anterior posición
            // para ver si hay una parada
            if (this.oldPos) {
                if (pos.Fecha.getTime() - this.oldPos.Fecha.getTime() >= this.config.tiempoParada) {
                    this.setParada(this.oldPos, (pos.Fecha.getTime() - this.oldPos.Fecha.getTime()) / 1000,
                        this.oldPos.Fecha, pos.Fecha);
                }
            }
            // Pongo la flecha que marca el sentido de la marcha
            this.drawDirectionArrow(pos);
            this.oldPos = pos;
            // Me quedo con las coordenadas de las esquinas
            if (pos.Lat > neCorner.lat) {
                neCorner.lat = pos.Lat;
            }
            if (pos.Lng > neCorner.lng) {
                neCorner.lng = pos.Lng;
            }
            if (pos.Lat < swCorner.lat) {
                swCorner.lat = pos.Lat;
            }
            if (pos.Lng < swCorner.lng) {
                swCorner.lng = pos.Lng;
            }
        });
        // Pongo el marcador de final de recorrido
        this.setFinal(this.posiciones[this.posiciones.length - 1]);
        this.sensores.forEach(sensor => {
            if (this.sensoresSelec.get(sensor.SensorId)) {
                this.setSensor(sensor, false);
            }
        });
        // Realizo un zoom de encuadre para que se vea toda la ruta
        if (!this.colorFix) {
            this.map.fitTo(new MapBounds(swCorner, neCorner));
        }
    }

    drawDirectionArrow(pos: PosicionModel) {
        if (this.oldPos && GeoUtils.getDistance(pos.Lat, pos.Lng, this.oldPosDirection.lat, this.oldPosDirection.lng) > 100) {
            this.oldPosDirection = new MapLatLng(pos.Lat, pos.Lng);
            // Calculo los dos lados del triángulo (el tercero es de 90º)
            const a = pos.Lat - this.oldPos.Lat;
            const b = pos.Lng - this.oldPos.Lng;
            let angle = Math.atan(a / b) * 180 / Math.PI;
            // Calculo el sentido de la marcha
            let dir = '';
            if (pos.Lat >= this.oldPos.Lat) dir = 'N';
            else dir = 'S';
            if (pos.Lng >= this.oldPos.Lng) dir += 'E';
            else dir += 'W';
            if (dir === 'NW' || dir === 'SW') {
                angle = 180 + angle;
            }
            // Dibujo la fecha en el sentido de la marcha
            const polygon = this.map.addPolygon({
                strokeColor: '#ff0000',
                strokeOpacity: 0.3,
                strokeWeight: 1,
                fillColor: '#0000ff',
                fillOpacity: 0.3
            });
            const a1 = (angle + 160) % 360;
            const a2 = (angle + 200) % 360;
            const p2 = new MapLatLng(pos.Lat + this.sin(a1) * 0.00005, pos.Lng + this.cos(a1) * 0.00005);
            const p3 = new MapLatLng(pos.Lat + this.sin(a2) * 0.00005, pos.Lng + this.cos(a2) * 0.00005);
            this.map.addPolygonPoint(polygon, {
                position: new MapLatLng(pos.Lat, pos.Lng),
            });
            this.map.addPolygonPoint(polygon, {
                position: p2
            });
            this.map.addPolygonPoint(polygon, {
                position: p3
            });
            this.polygons.push(polygon);
        }
    }

    sin(angle: number) {
        return Math.sin(angle * Math.PI / 180);
    }

    cos(angle: number) {
        return Math.cos(angle * Math.PI / 180);
    }

    // Reproduce el recorrido paso a paso
    async playStep2Step(color: string): Promise<void> {
        // Inicio una nueva reproducción desde el principio
        this.clear();
        await this.getConfiguration();
        this.colorFix = color !== null ? true : false;
        this.color = color;
        this.oldPos = null;
        this.polyline = null;
        this.oldColor = '';
        this.oldPosDirection = new MapLatLng(0, 0);
        // Creo el icono para el móvil
        this.setMarkerMovil(this.posiciones[0]);
        // Pongo el marcador de inicio de recorrido
        this.setInicio(this.posiciones[0]);
        if (!this.colorFix) {
            this.map.setCenter(new MapLatLng(this.posiciones[0].Lat, this.posiciones[0].Lng));
            this.map.setZoom(14);
        }
        // Arranco el temporizador que reproduce la ruta paso a paso
        this.setIntervalS2S();
    }

    // Crea el temporizador que reproduce la ruta paso a paso
    setIntervalS2S() {
        if (this.timerS2S) {
            clearInterval(this.timerS2S);
        }
        this.timerS2S = setInterval(() => {
            if (this.indexS2S < this.posiciones.length) {
                const pos = this.posiciones[this.indexS2S];
                // Si el color no es fijo cojo el color en función de lo configurado
                if (!this.colorFix) {
                    this.color = this.getColor(pos.Velocidad, pos.Fecha);
                }
                // Si cambia el color creo una nueva polilinea
                if (this.color !== this.oldColor || !this.polyline) {
                    if (this.polyline) {
                        this.map.addPolylinePoint(this.polyline, {
                            dataModel: pos,
                            content: DateUtils.formatDateTimeShort(pos.Fecha, true) + '<br>' + pos.Velocidad + ' km/h',
                            position: new MapLatLng(pos.Lat, pos.Lng)
                        });
                    }
                    this.polyline = this.addPolyline(this.color);
                    this.oldColor = this.color;
                }
                const newPos = new MapLatLng(pos.Lat, pos.Lng);
                // Añado puntos a la polilinea
                this.map.addPolylinePoint(this.polyline, {
                    dataModel: pos,
                    content: this.resourcesService.getMovil(pos.MovilId).Nombre + '<hr>' +
                        DateUtils.formatDateTimeShort(pos.Fecha, true) + '<br>' + pos.Velocidad + ' km/h',
                    position: newPos
                });
                this.markerMovil.setContent(this.resourcesService.getMovil(pos.MovilId).Nombre + '<hr>' +
                    DateUtils.formatDateTimeShort(pos.Fecha, true) + '<br>' + pos.Velocidad + ' km/h');
                this.markerMovil.setPosition(newPos);
                // Calculo el tiempo que ha pasado respecto a la anterior posición para ver si hay una parada
                if (this.oldPos !== null) {
                    if (pos.Fecha.getTime() - this.oldPos.Fecha.getTime() >= this.config.tiempoParada) {
                        this.setParada(this.oldPos, (pos.Fecha.getTime() - this.oldPos.Fecha.getTime()) / 1000,
                            this.oldPos.Fecha, pos.Fecha);
                    }
                }
                // Pinto los sensores que hayan hasta la fecha de esta posición
                while (this.sensorIndex < this.sensores.length && this.sensores[this.sensorIndex].Fecha <= pos.Fecha) {
                    if (this.sensoresSelec.get(this.sensores[this.sensorIndex].SensorId)) {
                        this.setSensor(this.sensores[this.sensorIndex], true);
                    }
                    this.sensorIndex++;
                }
                // Pongo la flecha que marca el sentido de la marcha
                this.drawDirectionArrow(pos);
                this.oldPos = pos;
                this.indexS2S++;
                // Si sólo se está reproduciendo el recorrido de un móvil y se sale del area visible centro el mapa
                if (TracksComponent.numInstances < 2 && !this.colorFix && !this.map.getBounds().contains(newPos)) {
                    this.map.panTo(newPos);
                }
            } else {
                // Pinto los sensores que puedan quedar
                while (this.sensorIndex < this.sensores.length) {
                    if (this.sensoresSelec.get(this.sensores[this.sensorIndex].SensorId)) {
                        this.setSensor(this.sensores[this.sensorIndex], false);
                    }
                    this.sensorIndex++;
                }
                this.setFinal(this.posiciones[this.posiciones.length - 1]);
                clearInterval(this.intervalS2S);
            }
        }, this.intervalS2S);
    }

    // Modifica la velocidad de reproducción paso a paso
    setInterval(interval: number) {
        this.intervalS2S = (1000 - interval * 10);
        if (!this.paused) {
            this.setIntervalS2S();
        }
    }

    // Pausa la reproducción de la ruta paso a paso
    pause(): void {
        if (!this.paused) {
            this.paused = true;
            if (this.timerS2S) {
                clearInterval(this.timerS2S);
            }
        } else {
            // Si ya estaba pausada continua con la reproducción
            this.paused = false;
            this.setIntervalS2S();
        }
    }

    // Para la reproducción paso a paso
    stop(): void {
        this.clear();
    }

    // Recupero el color en función de la velovidad
    getColor(velocidad: number, fecha: Date): string {
        switch (this.config.criterio) {
            case 'COLOR_FIJO':
                return '#' + this.config.colorFijo;
            case 'X_VELOCIDAD':
                if (velocidad < 61) {
                    return '#' + this.config.colorVel1;
                }
                if (velocidad < 101) {
                    return '#' + this.config.colorVel2;
                }
                return '#' + this.config.colorVel3;
            case 'X_DIAS_SEMANA':
                switch (fecha.getDay()) {
                    case 0:
                        return '#' + this.config.colorDo;
                    case 1:
                        return '#' + this.config.colorLu;
                    case 2:
                        return '#' + this.config.colorMa;
                    case 3:
                        return '#' + this.config.colorMi;
                    case 4:
                        return '#' + this.config.colorJu;
                    case 5:
                        return '#' + this.config.colorVi;
                }
                return '#' + this.config.colorSa;
            case 'X_SENSOR':
                // Busco el último estado del sensor elegido hasta la fecha de esta posición
                while (this.sensorIndexColor < this.sensores.length && this.sensores[this.sensorIndexColor].Fecha <= fecha) {
                    if (this.sensores[this.sensorIndexColor].SensorId === this.config.sensor) {
                        this.sensorColorEstado = Number.parseInt(this.sensores[this.sensorIndexColor].Valor);
                    }
                    this.sensorIndexColor++;
                }
                if (NumberUtils.isNaN(this.sensorColorEstado)) {
                    for (let i = this.sensorIndexColor; i < this.sensores.length; i++) {
                        if (this.sensores[i].SensorId === this.config.sensor) {
                            this.sensorColorEstado = Number.parseInt(this.sensores[i].Valor);
                            if (!NumberUtils.isNaN(this.sensorColorEstado)) {
                                this.sensorColorEstado = this.sensorColorEstado > 0 ? 0 : 1;
                            }
                            break;
                        }
                    }
                }
                if (this.sensorColorEstado > 0) {
                    return '#' + this.config.colorSensor1;
                }
                if (this.sensorColorEstado === 0) {
                    return '#' + this.config.colorSensor0;
                }
                return '#' + this.config.colorSensorX;
        }
    }

    // Creo una nueva polilinea
    addPolyline(color: string): MapPolyline {
        const polyline = this.map.addPolyline({
            color: color,
            weight: 7,
            opacity: 0.7,
            clickable: true
        });
        this.polylines.push(polyline);
        return polyline;
    }

    // Borra todas las polilineas, polígonos y marcadores del mapa
    clear(): void {
        if (this.timerS2S) {
            clearInterval(this.timerS2S);
        }
        this.timerS2S = null;
        this.polylines.forEach(polyline => {
            this.map.removePolyline(polyline);
        });
        this.polylines = [];
        this.markers.forEach(marker => {
            this.map.removeMarker(marker);
        });
        this.markers = [];
        this.polygons.forEach(polygon => {
            this.map.removePolygon(polygon);
        });
        this.polygons = [];
        this.paused = false;
    }

    // Pone el marcador de inicio de recorrido
    setInicio(posicion: PosicionModel): void {
        const marker = this.map.addMarker({
            dataModel: posicion,
            title: AppComponent.translate('Inicio') + ' ' + DateUtils.formatDateTimeShort(posicion.Fecha, true),
            content: this.resourcesService.getMovil(posicion.MovilId).Nombre + '<hr>' +
                DateUtils.formatDateTimeShort(posicion.Fecha, true),
            position: new MapLatLng(posicion.Lat, posicion.Lng),
            icon: '/assets/images/reproductor/ini-ruta.png',
            zIndex: 999,
            drag: false
        });
        this.markers.push(marker);
    }

    // Pone el marcador de final de recorrido
    setFinal(posicion: PosicionModel): void {
        const marker = this.map.addMarker({
            dataModel: posicion,
            title: AppComponent.translate('Fin') + ' ' + DateUtils.formatDateTimeShort(posicion.Fecha, true),
            content: this.resourcesService.getMovil(posicion.MovilId).Nombre + '<hr>' +
                DateUtils.formatDateTimeShort(posicion.Fecha, true),
            position: new MapLatLng(posicion.Lat, posicion.Lng),
            icon: '/assets/images/reproductor/fin-ruta.png',
            zIndex: 999,
            drag: false
        });
        this.markers.push(marker);
    }

    setMarkerMovil(posicion: PosicionModel): void {
        this.markerMovil = this.map.addMarker({
            dataModel: posicion,
            title: this.resourcesService.getMovil(posicion.MovilId).Nombre,
            content: this.resourcesService.getMovil(posicion.MovilId).Nombre + '<hr>' +
                DateUtils.formatDateTimeShort(posicion.Fecha, true) + '<br>' + posicion.Velocidad + ' km/h',
            position: new MapLatLng(posicion.Lat, posicion.Lng),
            icon: this.resourcesService.getMovil(posicion.MovilId).ConjuntoVehiculo.Icono !== null &&
                this.resourcesService.getMovil(posicion.MovilId).ConjuntoVehiculo.Icono.length > 10 ? 'data:image/png;base64,' +
            this.resourcesService.getMovil(posicion.MovilId).ConjuntoVehiculo.Icono : 'assets/images/car.png',
            zIndex: 999,
            drag: false
        });
        this.markers.push(this.markerMovil);
    }

    // Pone el marcador de parada, el tiempo de parada viene en segundos
    setParada(posicion: PosicionModel, tiempo: number, desde: Date, hasta: Date): void {
        // Calculo el tiempo de parada en formato hh:mm:ss
        let tParada = '';
        const hh = Math.trunc(tiempo / 3600);
        const mm = Math.trunc((tiempo - (hh * 3600)) / 60);
        const ss = Math.trunc(tiempo - (hh * 3600) - (mm * 60));
        tParada = hh.toString().padStart(2, '0') + ':';
        tParada += mm.toString().padStart(2, '0') + ':';
        tParada += ss.toString().padStart(2, '0');
        const marker = this.map.addMarker({
            dataModel: posicion,
            title: AppComponent.translate('Parada') + ' ' + tParada + ' ' + DateUtils.formatDateTimeShort(desde, true),
            content: '<b>' + this.resourcesService.getMovil(posicion.MovilId).Nombre + '</b><hr>' +
                AppComponent.translate('Desde') + ': ' + DateUtils.formatDateTimeShort(desde, true) + '<br>' +
                AppComponent.translate('Hasta') + ': ' + DateUtils.formatDateTimeShort(hasta, true) + '<br><p>' +
                AppComponent.translate('Tiempo') + ': ' + tParada + '</p>',
            position: new MapLatLng(posicion.Lat, posicion.Lng),
            icon: '/assets/images/reproductor/parada.png',
            zIndex: 0,
            drag: false
        });
        this.markers.push(marker);
    }

    // Pone el marcador de un sensor
    setSensor(sensor: SensorDatosModel, animate: boolean): void {
        let icono;
        // En el caso especial de las entradas digitales pongo el icono de la bombilla encendida
        // o apagada según el estado del sensor
        if (sensor.SensorId > 8) {
            icono = this.sensorService.getSensor(sensor.SensorId).Icono;
        } else {
            icono = Number.parseInt(sensor.Valor) > 0 ? 'assets/images/sensores/1.png' : 'assets/images/sensores/0.png';
        }
        const marker = this.map.addMarker({
            dataModel: sensor,
            title: sensor.NombreSensor + ' ' + sensor.Valor, content: sensor.NombreSensor + '<hr>' + '<b>' +
                sensor.NombreSensor + '</b>: ' + sensor.Valor + '<br>' + DateUtils.formatDateTime(sensor.Fecha, true),
            position: new MapLatLng(sensor.Lat, sensor.Lng),
            icon: icono,
            zIndex: 999,
            drag: false
        });
        this.markers.push(marker);
        if (animate) {
            marker.animate(2850);
        }
    }

}
