import {
  Input,
  OnInit,
  Component,
  ViewChild,
  SimpleChanges,
}from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { ElemInstalacionDetalleModel } from 'src/app/services/elements/models/instalacion/elem-instalacion-detalle.model';
import { ElemInstalacionDetalleNotifImagenModel } from 'src/app/services/elements/models/instalacion/elem-instalacion-detalle-notif-imagen.model';
import { ElementsEditComponent } from '../elements-edit.component';

@Component({
  selector: 'app-elements-edit-instalaciones',
  templateUrl: './elements-edit-instalaciones.component.html',
  styleUrls: ['./elements-edit-instalaciones.component.css', '../elements-edit.component.css']
})
export class ElementsEditInstalacionesComponent implements OnInit {
  @Input() elemEdit: ElementoModel;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public editTag: boolean = true;
  public editEcoLock: boolean = true;
  public editVolumetrico: boolean = true;

  public static _this: ElementsEditInstalacionesComponent;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    ElementsEditInstalacionesComponent._this = this
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            this.initGridTipoTag();
            this.initGridIncidenciasTag();
            this.initGridTipoVolumentrico();
            this.initGridIncidenciasVolum();
            this.initGridIncidenciasEcolock();
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  getDetalleInstalacion(tipo: number): ElemInstalacionDetalleModel {
    let detalleInstalacion: ElemInstalacionDetalleModel;

    if(this.elemEdit && this.elemEdit.Instalacion && this.elemEdit.Instalacion.Detalle) {
      detalleInstalacion = this.elemEdit.Instalacion.Detalle.find(det => det.IdTipo === tipo);
    }

    return detalleInstalacion;
  }

  addImageDetalle(event: any, tipo: number) {
    this.getDetalleInstalacion(tipo).Imagenes.push(event);
  }

  rmvImageDetalle(event: any, tipo: number) {
    this.getDetalleInstalacion(tipo).Imagenes.splice(this.getDetalleInstalacion(tipo).Imagenes.indexOf(event), 1);
    ElementsEditComponent._this.form.collapse();
    setTimeout(() => {
      ElementsEditComponent._this.form.expand();
    }, 250)
  }

  addImageNotificacion(event: any, selectNotif: any) {
    selectNotif.Imagenes.push(event);
  }

  rmvImageNotificacion(event: any, selectNotif: any) {
    selectNotif.Imagenes.splice(selectNotif.Imagenes.indexOf(event), 1);
    ElementsEditComponent._this.form.collapse();
    setTimeout(() => {
      ElementsEditComponent._this.form.expand();
    }, 300)
  }

  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement,
    from: number
  ) {
    let rowdata: any;
    htmlElement.innerHTML = '';

    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnSeeImgs = document.createElement('div');
    btnSeeImgs.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Ver_imagenes')+`">
          <i class="fa-solid fa-eye"></i>
        </button>
      `;
    btnSeeImgs.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnSeeImgs);

    btnSeeImgs.addEventListener('click', async (event: any) => {
      switch(from) {
        case 1: // Tag
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasTag.getrowdata(row);
          }

          this.incdTagSelec = null;

          this.incdTagSelec = this.getDetalleInstalacion(1).Notificaciones[this.getDetalleInstalacion(1).Notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        case 2: // Volumetrico
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasVolum.getrowdata(row);
          }

          this.incdVolumSelec = null;

          this.incdVolumSelec = this.getDetalleInstalacion(2).Notificaciones[this.getDetalleInstalacion(2).Notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        case 3: // Ecolock
          if (isNaN(row)) {
            rowdata = row.bounddata;
          } else {
            rowdata = this.gridIncidenciasEcolock.getrowdata(row);
          }

          this.incdEcolockSelec = null;

          this.incdEcolockSelec = this.getDetalleInstalacion(3).Notificaciones[this.getDetalleInstalacion(3).Notificaciones.findIndex(not => not.Id === rowdata.id)];
          break;
        default:
          break;
      }
    });

    htmlElement.appendChild(btnContainer);
  }

  sourceEstadoInstalacion =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 4 },
    { label: AppComponent.translate('Sin_lectura'), value: 5 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 6 },
    { label: AppComponent.translate('Instalado_sin_cobertura'), value: 29 },
  ];

  /* APARTADO INSTALACION VOLUMETRICO */
  /* Tipo volumetrico */
  @ViewChild('dropTipoDispositivoVolum') dropTipoDispositivoVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoVolum') gridDropDownTipoDispositivoVolum: jqxGridComponent;
  gridDropTipoDispositivoVolum: boolean;

  columnsTipoDispositivoVolum;
  public sourceTipoDispositivoVolum;
  public dataAdapterTipoDispositivoVolum;

  initGridTipoVolumentrico() {
    this.columnsTipoDispositivoVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true }
    ];

    this.sourceTipoDispositivoVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoVolum(event:any){

  }

  onCloseDropTipoDispositivoVolum(event:any){

  }

  onRowClickTipoDispositivoVolum(event:any){

  }

  /* Estado instalacion volumetrico */
  @ViewChild('dropEstadoInstalacionVolum') dropEstadoInstalacionVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionVolum') gridDropDownEstadoInstalacionVolum: jqxGridComponent;
  gridDropEstadoInstalacionVolum: boolean;

  columnsEstadoInstalacionVolum;
  public dataAdapterEstadoInstalacionVolum;

  sourceEstadoInstalacionVolum =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 4 },
    { label: AppComponent.translate('Sin_lectura'), value: 5 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 6 },
  ];

  onChangeInstalacionVolumetrico(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereVolum = true;
    } else {
      this.elemEdit.RequiereVolum = false;
    }
  }

  onOpenDropEstadoInstalacionVolum(event:any){

  }

  onCloseDropEstadoInstalacionVolum(event:any){

  }

  onRowClickEstadoInstalacionVolum(event:any){

  }

  /* Grid incidencias instalacion volumetrico */
  @ViewChild('gridIncidenciasVolum') gridIncidenciasVolum: jqxGridComponent;
  columnsIncidenciasVolum;
  public sourceIncidenciasVolum;
  public dataAdapterIncidenciasVolum;

  initGridIncidenciasVolum() {
    this.columnsIncidenciasVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 2);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 2);
        }
      },
    ];

    this.sourceIncidenciasVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(2)?.Notificaciones?.length > 0 ? this.getDetalleInstalacion(2).Notificaciones : []
    };

    this.dataAdapterIncidenciasVolum = new jqx.dataAdapter(this.sourceIncidenciasVolum);
  }

  /* Imagenes incidencias instalacion volumetrico */
  public incdVolumSelec: any;

  /* FIN APARTADO INSTALACION VOLUMETRICO */

  /* APARTADO INSTALACION TAG */
  /* Tipo tag */
  @ViewChild('dropTipoDispositivoTag') dropTipoDispositivoTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoTag') gridDropDownTipoDispositivoTag: jqxGridComponent;
  gridDropTipoDispositivoTag: boolean;

  columnsTipoDispositivoTag;
  public sourceTipoDispositivoTag;
  public dataAdapterTipoDispositivoTag;

  initGridTipoTag() {
    this.columnsTipoDispositivoTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceTipoDispositivoTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoTag(event:any){

  }

  onCloseDropTipoDispositivoTag(event:any){

  }

  onRowClickTipoDispositivoTag(event:any){

  }

  /* Estado instalacion tag */
  @ViewChild('dropEstadoInstalacionTag') dropEstadoInstalacionTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionTag') gridDropDownEstadoInstalacionTag: jqxGridComponent;
  gridDropEstadoInstalacionTag: boolean;

  columnsEstadoInstalacionTag;
  public dataAdapterEstadoInstalacionTag;
  public sourceEstadoInstalacionTag =
  [
    { label: AppComponent.translate('Pendiente_instalacion'), value: 4 },
    { label: AppComponent.translate('Sin_lectura'), value: 5 },
    { label: AppComponent.translate('Instalado_correctamente'), value: 6 },
  ];

  onChangeInstalacionTag(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereTag = true;
    } else {
      this.elemEdit.RequiereTag = false;
    }
  }

  onOpenDropEstadoInstalacionTag(event:any){

  }

  onCloseDropEstadoInstalacionTag(event:any){

  }

  onRowClickEstadoInstalacionTag(event:any){

  }

  /* Grid incidencias instalacion tag */
  @ViewChild('gridIncidenciasTag') gridIncidenciasTag: jqxGridComponent;

  columnsIncidenciasTag;
  public sourceIncidenciasTag;
  public dataAdapterIncidenciasTag;

  initGridIncidenciasTag() {
    this.columnsIncidenciasTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 1);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 1);
        }
      },
    ];

    this.sourceIncidenciasTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(1)?.Notificaciones?.length > 0 ? this.getDetalleInstalacion(1).Notificaciones : []
    };

    this.dataAdapterIncidenciasTag = new jqx.dataAdapter(this.sourceIncidenciasTag);
  }

  /* Imagenes incidencias instalacion tag */
  public incdTagSelec: any;

  /* FIN APARTADO INSTALACION TAG */

  /* APARTADO INSTALACION ECOLOCK */
  /* Instalacion ecolock */
  onChangeInstalacionEcolock(event) {
  }

  /* Instalacion identificación */
  onChangeInstalacionIdentificacion(event) {
  }

  /* Identificacion ecolock */
  @ViewChild('dropIdentificacionEcolock') dropIdentificacionEcolock: jqxDropDownListComponent;

  /* Opciones dropdown identificacion ecolock */
  sourceIdentificacionEcolock =
  [
    { label: AppComponent.translate('Tarjeta_nfc'), value: 0 },
    { label: 'Bluetooth', value: 1 },
  ];

  /* Estado instalacion ecolock */
  @ViewChild('dropEstadoInstalacionEcolock') dropEstadoInstalacionEcolock: jqxDropDownListComponent;

  /* Tipo dispositivo ecolock */
  @ViewChild('dropTipoDispositivoEcolock') dropTipoDispositivoEcolock: jqxDropDownListComponent;
  sourceTipoDispositivoEcolock = [];

  /* Grid incidencias instalacion ecolock */
  @ViewChild('gridIncidenciasEcolock') gridIncidenciasEcolock: jqxGridComponent;

  columnsIncidenciasEcolock;
  public sourceIncidenciasEcolock;
  public dataAdapterIncidenciasEcolock;

  initGridIncidenciasEcolock() {
    this.columnsIncidenciasEcolock =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
      {
        text: this.translate('Informacion_grafica'), datafield: 'informacionGrafica', width: 150,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement, 3);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement, 3);
        }
      },
    ];

    this.sourceIncidenciasEcolock = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
        { name: 'fecha', type: 'date', map: 'Fecha'},
        { name: 'descripcion', type: 'string', map: 'Texto' },
        { name: 'informacionGrafica', map: 'Imagenes' },
      ],
      localdata: this.getDetalleInstalacion(3)?.Notificaciones?.length > 0 ? this.getDetalleInstalacion(3).Notificaciones : []
    };
    this.dataAdapterIncidenciasEcolock = new jqx.dataAdapter(this.sourceIncidenciasEcolock);
  }

  /* Imagenes incidencias instalacion ecolock */
  public incdEcolockSelec: any;
  /* FIN APARTADO INSTALACION ECOLOCK */
}
