import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from '../header/header.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';

@Component({
  selector: 'app-tarjetas-ciudadanos',
  templateUrl: './tarjetas-ciudadanos.component.html',
  styleUrls: ['./tarjetas-ciudadanos.component.css']
})
export class TarjetasCiudadanosComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridTarjetas') gridTarjetas: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: TarjetasCiudadanosComponent;

  public checkTarjVirtual = AppComponent.translate('Todas');

  public showLoader = false;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  tarjetas: TarjetaModel[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private tarjetasService: TarjetasService,
    private ssoService: SsoService
  ) {
    super();
    TarjetasCiudadanosComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.initGrid();
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(this.translate('Tarjetas_ciudadanos') + ' (' + this.tarjetas.length + ')');
    this.addCustomForm(this.form);

    this.getTarjetas();
  }

  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    TarjetasCiudadanosComponent._this = null;
  }

  public columnsTarjetas;

  public sourceTarjetas;
  public adapterTarjetas;

  // Creacion de los botones de accion de las columnas
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridTarjetas.getrowdata(row);
    }

    let tarjeta: TarjetaModel = this.tarjetas.find(tarj => tarj.id === rowdata.id);

    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    btnContainer.style.justifyContent = 'center';

    // Boton de borrar, solo se muestra cuando la tarjeta no tiene ciudadano
    if (tarjeta && !tarjeta.ciudadano) {
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;

      btnDelete.addEventListener('click', (event: any) => {
        this.onBorrarTarjeta(rowdata);
      });
      btnContainer.appendChild(btnDelete);
    }

    htmlElement.appendChild(btnContainer);
  }

  initGrid() {
    this.columnsTarjetas = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
      {
        width: '40px',
        text: this.translate('Acciones'),
        columntype: 'text',
        editable: false,
        datafield: this.translate('Acciones'),
        groupable: false,
        menu: false,
        sortable: false,
        clickable: false,
        filterable: false,
        resizable: false,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      {
        text: this.translate('Grabada'), columntype: 'checkbox', datafield: this.translate('Grabada'), width: 60, cellsrenderer: this.renderCheck,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO')
        ],
      },
      {
        text: this.translate('Modo'), columntype: 'textbox', datafield: this.translate('Virtual'), width: 50, cellsrenderer: this.renderTipo,
        filtertype: 'checkedlist',
        filteritems: [
          'F',
          'V'
        ],
      },
      {
        text: this.translate('Tipo'), columntype: 'textbox', datafield: this.translate('Tipo'), width: 60, cellsrenderer: this.renderMasterColumn,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('Master'),
          this.translate('Blanca'),
        ],
      },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: this.translate('Ns_movisat'), width: 120 },
      { text: this.translate('Ciudadano'), columntype: 'textbox', datafield: this.translate('Ciudadano'), width: 120 },
      { text: this.translate('Direccion'), columntype: 'textbox', datafield: this.translate('Direccion'), width: 450,
        calculateFilterExpression: function(filterValue, selectedFilterOperation, target){
            var getter = function(data) {
              return data['PrisonDesc'].normalize('NFD').replace(/[\u0300-\u036f]/g,"");
            };

            filterValue = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g,"");

            return [getter, selectedFilterOperation || "contains", filterValue];
        }
      },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    this.sourceTarjetas = {
      datatype: 'json',
      groupsrenderer: this.groupsrenderer,
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: this.translate('Acciones'), type: 'string' },
        { name: this.translate('Grabada'), type: 'boolean', map: 'grabada' },
        { name: this.translate('Virtual'), type: 'string', map: 'virtual' },
        { name: this.translate('Tipo'), type: 'string', map: 'master' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsMovisat' },
        { name: this.translate('Ciudadano'), type: 'string', map: 'nombreCiudadano' },
        { name: this.translate('Direccion'), type: 'string', map: 'direccionText' },
        { name: 'selec', map: 'selec', type: 'string' }
      ],
      localdata: this.tarjetas,
    };
    this.adapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);
  }

  renderMasterColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">' + this.translate('Master') + '</div>';
    }
    else {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">' + this.translate('Blanca') + '</div>';
    }
  };

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    let showText: string;

    if (data.groupcolumn.datafield === AppComponent.translate('Grabada')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Grabada') + `: </b>
      `;

      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    } else if (data.groupcolumn.datafield === AppComponent.translate('Virtual')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Modo') + `: </b>
      `;

      if (group === true) {
        showText += 'V';
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += 'F';
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    } else if (data.groupcolumn.datafield === this.translate('Tipo')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Tipo') + `: </b>
      `;

      if (group === true) {
        showText += AppComponent.translate('Master');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += AppComponent.translate('Blanca');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    }
  }

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">V</div>';
    } else {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">F</div>';
    }
  }

  onBorrarTarjeta(data: any) {
    this.tarjetasService.deleteTarjeta(data.id).then(
      (response: Boolean) => {
        MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
        this.getTarjetas();
      }, (error: any) => {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Ha_ocurrido_un_error',
          2000
        );
      }
    )
  }

  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    } else {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  renderDireccionTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any): string {
    let showText = '';

    if (value) {
      showText =
        (`<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">` +
          (value.direccion ? value.direccion : '') +
          (value.numero ? ', ' + value.numero : '') +
          (value.poblacion ? ', ' + value.poblacion : '') +
          (value.provincia ? ', ' + value.provincia : '') +
          `</div>`);
    }

    return showText;
  }

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    if (
      dataField === AppComponent.translate('Ns_movisat') ||
      dataField === AppComponent.translate('Ciudadano') ||
      dataField === AppComponent.translate('Direccion')
    ) {
      /* let filters = filterGroup.getfilters();
      for (let i = 0; i < filters.length; i++) {
        let filter = filters[i];
        let filterValue = filter.value;
        if (cellValue.normaliceAccents().toUpperCase().indexOf(filterValue.normaliceAccents().toUpperCase()) >= 0) {
          return true;
        }
      }
      return false; */
      let filters = filterGroup.getfilters();
      if (cellValue != "" && cellValue != null) {
        let cellValueWithoutAccents = cellValue.normaliceAccents().toUpperCase();
        let exists = false;
        for (let i = 0; i < filters.length; i++) {
          let filter = filters[i];
          let filterValue = filter.value;

          let filterCondition = filter.condition;
          let filterOperator = filter.operator;

          let filterValueWithoutAccents = filterValue.normaliceAccents().toUpperCase();
          if (cellValueWithoutAccents.includes(filterValueWithoutAccents)) {
            exists = true;
          }
          else {
            if(filterOperator === 0) {
              exists = false;
            }
          }
        }
        return exists;
      }
      else {
        return defaultFilterResult;
      }
    }
  }

  onFilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === this.translate('Grabada')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('SI')) {
            filtervalue = true;
          } else if (filt.value === this.translate('NO')) {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
            this.gridTarjetas.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
          }
        });
      } else if (column === this.translate('Virtual')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === 'V') {
            filtervalue = true;
          } else if (filt.value === 'F') {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
            this.gridTarjetas.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
          }
        });
      } else if (column === this.translate('Tipo')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('Master')) {
            filtervalue = true;
          } else if (filt.value === this.translate('Blanca')) {
            filtervalue = false;
          } else {
            filtervalue = undefined;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
            this.gridTarjetas.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridTarjetas.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  async getTarjetas() {
    this.tarjetas = await this.tarjetasService.getTarjetasWithCiudadanos();

    if (!this.tarjetas) {
      this.tarjetas = [];
    }

    this.tarjetas.forEach(element => {
      if (element.ciudadano) {
        element['nombreCiudadano'] = element.ciudadano.nombre + ' ' + element.ciudadano.apellidos;
      } else {
        element['nombreCiudadano'] = '';
      }

      element['direccionText'] =
        (element?.direccion?.direccion ? element?.direccion?.direccion : '') +
        (element?.direccion?.numero ? ', ' + element?.direccion?.numero : '') +
        (element?.direccion?.poblacion ? ', ' + element?.direccion?.poblacion : '') +
        (element?.direccion?.provincia ? ', ' + element?.direccion?.provincia : '');
    });

    this.tarjetas.sort((a, b) => (a.nsMovisat > b.nsMovisat) ? 1 : ((b.nsMovisat > a.nsMovisat) ? -1 : 0));

    this.sourceTarjetas.localdata = this.tarjetas;
    this.gridTarjetas.updatebounddata(this.tarjetas);

    this.onBuscar();
  }

  onCheckTarjVirtual(event: any) {
    this.checkTarjVirtual = event;

    this.onBuscar();
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getTarjetas();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value;
    } else {
      filtervalue = '';
    }

    this.tarjetas.forEach(tarj => {
      if (
        (
          tarj?.nsMovisat?.normaliceAccents().toUpperCase().indexOf(filtervalue?.normaliceAccents().toUpperCase()) > -1 ||
          tarj?.nombreCiudadano?.normaliceAccents().toUpperCase().indexOf(filtervalue?.normaliceAccents().toUpperCase()) > -1 ||
          (
            (tarj?.direccion?.direccion ? tarj.direccion.direccion : '') +
            (tarj?.direccion?.numero ? ', ' + tarj.direccion.numero : '') +
            (tarj?.direccion?.poblacion ? ', ' + tarj.direccion.poblacion : '') +
            (tarj?.direccion?.provincia ? ', ' + tarj.direccion.provincia : '')
          )?.normaliceAccents().toUpperCase().indexOf(filtervalue?.normaliceAccents().toUpperCase()) > -1
        ) &&
        (
          this.checkTarjVirtual === AppComponent.translate('Todas') ? true :
          this.checkTarjVirtual === AppComponent.translate('Virtuales') && tarj.virtual === true ? true :
          this.checkTarjVirtual === AppComponent.translate('Fisicas') && tarj.virtual === false ? true :
          false
        )
      ) {
        tarj['selec'] = 'selec';
      } else {
        tarj['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridTarjetas.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridTarjetas.addfilter('selec', filtergroup);
    }
    this.gridTarjetas.applyfilters();
    this.gridTarjetas.updatebounddata('data');

    this.form?.setTitle(this.translate('Tarjetas_ciudadanos') + ' (' + this.tarjetas.length + ')');
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.gridTarjetas.getrows()));
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Tarjetas_ciudadanos') + '.xlsx');
  }

  closeWindow() {
    this.form.close();
  }
}
