<jqxWindow #uploadImageWind [width]="400" [height]="150" [zIndex]="101" [isModal]="true" [position]="'center'"
  [showCloseButton]="true" [resizable]="false" [autoOpen]="false" [theme]="environment.tema"
  [title]="translate('Subida_imagen')" (onClose)="onCloseWindowImage()">
  <div style="background-color: transparent;">
    <div class="row" style="overflow: hidden; background-color: transparent;">
      <span style="width: 100%; height: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Selecciona_imagen") }}
        </div>
        <input [(ngModel)]="inputFile" type="file" accept="image/png, image/jpeg" (change)="onSelectImage($event)">
      </span>
    </div>

    <jqxButton style="float: right; margin-bottom: 2%;" [width]="80" [height]="30" (onClick)="uploadImage()">
      {{translate('Guardar')}}
    </jqxButton>
  </div>
</jqxWindow>

<div class="row" style="height: calc(100% - 8px);">
  <div class="container imagenPrincipal">
    <div class="row" style="height: calc(100% - 35px)">
      <div *ngIf="selectedImg != null" style="height: 100%;">
        <img #imgPrincipalContainer src="data:image/png;base64,{{selectedImg?.Imagen != null ? selectedImg?.Imagen : selectedImg?.imagen}}"
          style="width: 90%; height: 90%;">
      </div>
    </div>

    <div class="row" style="margin-top: 5px;">
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/previous.png"' [imgPosition]="'center'"  [disabled]="imagenes?.length <= 1 && imagenes?.length <= 1"
        (onClick)="changeImage('previous')">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/next.png"' [imgPosition]="'center'" [disabled]="imagenes?.length <= 1 && imagenes?.length <= 1"
        (onClick)="changeImage('next')">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/rotate-left.png"' [imgPosition]="'center'" [disabled]="selectedImg == null"
        (onClick)="rotate('left')">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/rotate-right.png"' [imgPosition]="'center'" [disabled]="selectedImg == null"
        (onClick)="rotate('right')">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/refresh.png"' [imgPosition]="'center'" [disabled]="selectedImg == null"
        (onClick)="rotate('180')">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/upload.png"' [imgPosition]="'center'" [disabled]="imagenes == null"
        (onClick)="openUploadImageWind()">
      </jqxButton>
      <jqxButton style="float:left;" [width]="35" [height]="35" [imgHeight]="25" [imgWidth]="25"
        [imgSrc]='"/assets/images/borrar.png"' [imgPosition]="'center'" [disabled]="selectedImg == null"
        (onClick)="openDeleteImageWind()">
      </jqxButton>
    </div>
  </div>

  <div class="container sliderImages">
    <div *ngFor="let img of imagenes; let i = index"
      style="margin: 4% 0%; align-items: center; background-color: white; padding: 6px; width: 75px; height: 80px;">
      <div class="min-image" style="width: 100%; height: 100%;">
        <img class="min-image"
          style="margin-left: 10px; padding: auto; align-items: center; width: 40px; height: 40px;"
          src="data:image/png;base64,{{img.Imagen != null ? img?.Imagen : img?.imagen}}" (click)="setImgPrincipal(img)">
      </div>
    </div>
  </div>
</div>
