import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { ListadoVolumComponent } from '../listado-volum/listado-volum.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-total-volum',
  templateUrl: './total-volum.component.html',
  styleUrls: ['./total-volum.component.css']
})
export class TotalVolumComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('grid2') grid2: jqxGridComponent;
  @ViewChild('grid3') grid3: jqxGridComponent;
  @ViewChild('grid4') grid4: jqxGridComponent;
  @ViewChild('grid5') grid5: jqxGridComponent;
  @ViewChild('formTotal') formTotal: jqxWindowComponent;

  private componentRef = null;
  private parentComponent = null;
  public environment = environment;
  public rowSelec = null;
  private elementosNoInstalados: ElementoModel[] = [];
  private elementosInstaladosNoHanEnviadoNunca: ElementoModel[] = [];
  private elementosConMedidasSinVolumetrico: ElementoModel[] = [];
  private elementosNoEnvianDesdeHaceMas1Dia: ElementoModel[] = [];
  private elementosEnviandoAlDia: ElementoModel[] = [];
  public totalElem: number;

  // Definiciones de tiempo
  private _1DIA = 3600 * 24;

  // Variables para los grid
  public dataSource1;
  public dataAdapter1;
  public dataSource2;
  public dataAdapter2;
  public dataSource3;
  public dataAdapter3;
  public dataSource4;
  public dataAdapter4;
  public dataSource5;
  public dataAdapter5;

  // Pendientes de instalación
  public columns1 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 192,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Volumetrico') + ' IMEI', columntype: 'textbox', filtertype: 'textbox',
      datafield: 'imei', width: 150
    }
  ];

  // Instalados que no han enviado nunca
  public columns2 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 192,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Volumetrico') + ' IMEI', columntype: 'textbox', filtertype: 'textbox',
      datafield: 'imei', width: 150
    }
  ];

  // No tienen volumétrico pero lo han tenido
  public columns3 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 192,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Ultima_lectura'), columntype: 'textbox',
      cellsformat: 'dd/MM/yy HH:mm:ss', filtertype: 'date', datafield: 'fecha', width: 150
    }
  ];

  // Hace un día o más que no envían
  public columns4 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 292,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Ultima_lectura'), columntype: 'textbox',
      cellsformat: 'dd/MM/yy HH:mm:ss', filtertype: 'date', datafield: 'fecha', width: 150
    },
    {
      text: AppComponent.translate('Dias'), columntype: 'textbox', filtertype: 'textbox',
      align: 'center', cellsalign: 'center', datafield: 'dias', width: 80
    }
  ];

  // Enviando que envian bien (menos de un día)
  public columns5 = [
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'elemento', width: 380,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center; margin-left: 4px;">' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Ultima_lectura'), columntype: 'textbox',
      cellsformat: 'dd/MM/yy HH:mm:ss', filtertype: 'date', datafield: 'fecha', width: 150
    }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService, private elmentsService: ElementsService) {
    super();
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.formTotal);
    this.formTotal.setTitle(AppComponent.translate('Volumetricos') + ' ' +
      AppComponent.translate('Total').toLowerCase());
    const t = setTimeout(() => {
      clearTimeout(t);
      this.procesaElementos();
      this.rellenaTabla1();
      this.rellenaTabla2();
      this.rellenaTabla3();
      this.rellenaTabla4();
      this.rellenaTabla5();
    }, 500);
  }

  // Este método es llamado por el creador del componente
  public init(params: any) {
    this.componentRef = params.component;
    this.parentComponent = params.parentComponent;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoVolumComponent.getInstance().totalVisible = false;
    if (this.parentComponent) {
      this.parentComponent.expand();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  resizeColumns(grid: jqxGridComponent){
    Utils.renderSizeGrid(grid, 500);
  }

  procesaElementos() {
    this.totalElem = 0;
    // Espero a que se descarguen los elementos
    MainComponent.onLoadElements((elementos: ElementoModel[]) => {
      elementos.forEach(elem => {
        if (elem.RequiereVolum) {
          this.totalElem++;
          switch (elem.EstadoInstalVolum) {
            case 1: // Instalados pendiente recibur lectura
              if (!elem.UltFechaVolum || elem.UltFechaVolum.getTime() < new Date(2000, 1, 1).getTime()) {
                this.elementosInstaladosNoHanEnviadoNunca.push(elem);
              }
              break;
            case 2: // Instalados correctamente
              if (!elem.UltFechaVolum || elem.UltFechaVolum.getTime() < new Date(2000, 1, 1).getTime()) {
                this.elementosInstaladosNoHanEnviadoNunca.push(elem);
              }
              break;
            default: // Pendiente instalación
              this.elementosNoInstalados.push(elem);
          }
          if ((!elem.ImeiVolum || elem.ImeiVolum.length < 15) && // No tiene imei actualmente
            (elem.IdVolumetrico && elem.IdVolumetrico.length > 15) && // Pero tiene id contenedor
            (elem.UltFechaVolum && elem.UltFechaVolum.getTime() > new Date(2000, 1, 1).getTime())) {
            this.elementosConMedidasSinVolumetrico.push(elem);
          }
        }
        if (elem.UltFechaVolum && elem.UltFechaVolum > new Date(2000, 1, 1)) {
          const dif = (new Date().getTime() - elem.UltFechaVolum.getTime()) / 1000;
          if (dif >= this._1DIA) {
            let dias = NumberUtils.format(dif / this._1DIA, 0);
            while (dias.length < 5) {
              dias = ' ' + dias;
            }
            elem['dias'] = dias;
            this.elementosNoEnvianDesdeHaceMas1Dia.push(elem);
          } else {
            this.elementosEnviandoAlDia.push(elem);
          }
        }
      });
    });
  }

  rellenaTabla1() {
    this.dataSource1 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Nombre', type: 'string' },
        { name: 'imei', map: 'ImeiVolum', type: 'string' }
      ],
      localdata: this.elementosNoInstalados,
      sortcolumn: 'elemento',
      sortdirection: 'asc'
    };
    this.dataAdapter1 = new jqx.dataAdapter(this.dataSource1);

    this.resizeColumns(this.grid);
  }

  rellenaTabla2() {
    this.dataSource2 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Nombre', type: 'string' },
        { name: 'imei', map: 'ImeiVolum', type: 'string' }
      ],
      localdata: this.elementosInstaladosNoHanEnviadoNunca,
      sortcolumn: 'elemento',
      sortdirection: 'asc'
    };
    this.dataAdapter2 = new jqx.dataAdapter(this.dataSource2);

    this.resizeColumns(this.grid2);
  }

  rellenaTabla3() {
    this.dataSource3 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Nombre', type: 'string' },
        { name: 'fecha', map: 'UltFechaVolum', type: 'date' }
      ],
      localdata: this.elementosConMedidasSinVolumetrico,
      sortcolumn: 'elemento',
      sortdirection: 'asc'
    };
    this.dataAdapter3 = new jqx.dataAdapter(this.dataSource3);

    this.resizeColumns(this.grid3);
  }

  rellenaTabla4() {
    this.dataSource4 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Nombre', type: 'string' },
        { name: 'fecha', map: 'UltFechaVolum', type: 'date' },
        { name: 'dias', map: 'dias', type: 'string' },
      ],
      localdata: this.elementosNoEnvianDesdeHaceMas1Dia,
      sortcolumn: 'fecha',
      sortdirection: 'desc'
    };
    this.dataAdapter4 = new jqx.dataAdapter(this.dataSource4);

    this.resizeColumns(this.grid4);
  }

  rellenaTabla5() {
    this.dataSource5 = {
      datatype: 'json',
      datafields: [
        { name: 'elemento', map: 'Nombre', type: 'string' },
        { name: 'fecha', map: 'UltFechaVolum', type: 'date' }
      ],
      localdata: this.elementosEnviandoAlDia,
      sortcolumn: 'fecha',
      sortdirection: 'desc'
    };
    this.dataAdapter5 = new jqx.dataAdapter(this.dataSource5);

    this.resizeColumns(this.grid5);
  }

  onRowClick1(event: any) {
    this.rowSelec = this.elementosNoInstalados[event.args.rowindex];
  }

  onRowClick2(event: any) {
    this.rowSelec = this.elementosInstaladosNoHanEnviadoNunca[event.args.rowindex];
  }

  onRowClick3(event: any) {
    this.rowSelec = this.elementosConMedidasSinVolumetrico[event.args.rowindex];
  }

  onRowClick4(event: any) {
    this.rowSelec = this.elementosNoEnvianDesdeHaceMas1Dia[event.args.rowindex];
  }

  onRowClick5(event: any) {
    this.rowSelec = this.elementosEnviandoAlDia[event.args.rowindex];
  }

  onCentrar(event: any) {
    if (this.rowSelec && this.rowSelec.Marker) {
      this.formTotal.collapse();
      const map = MainComponent.getActiveMap();
      map.setCenter(this.rowSelec.Marker.position);
      map.setZoom(18);
      this.rowSelec.Marker.animate(2850);
    }
  }

}
