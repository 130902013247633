<jqxWindow #windowCiudadanosForm [width]="mapWidth" [height]="mapHeight" [zIndex]="999" [theme]="environment.tema"
  [isModal]="false" [position]="getFormPos(windowCiudadanosForm, 0)" [showCloseButton]="true" [resizable]="true"
  [showCollapseButton]="true" (onClose)="onClose()"
  [title]="translate('Ficha_ciudadano') + ': ' + ciudadano.nombre + ' ' + ciudadano.apellidos">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Ficha_ciudadano") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #ciudRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 30px)'" (onSelect)="tabSelected($event)">
      <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li>
          {{ translate('Informacion') }}
        </li>
        <li>
          {{ translate('Direcciones') }}
        </li>
        <li>
          {{ translate('Tarjetas') }}
        </li>
      </ul>

      <div>
        <!-- PESTAÑA DEL FORMULARIO -->
        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div #fieldsetPersonal id="fieldsetPersonal">
              <fieldset>
                <legend>{{ translate('Personal_contacto') }}</legend>

                <div class="row">
                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Nombre') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.nombre" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 30%;">
                    <div style="clear: both">
                      {{ translate('Apellidos') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.apellidos" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Dni') }} <span *ngIf="ciudadanoDniExist" style="color: #d40c0c;">{{translate('DNI_existe')}}</span>
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.nif" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 15%;">
                    <div style="clear: both">
                      {{ translate('Estado') }}
                    </div>
                    <!-- <jqxInput *ngIf="ciudadano.id !== 0" [value]="ciudadano.validadoTexto" style="float: left"
                      [width]="'100%'" [disabled]="true">
                    </jqxInput> -->

                    <jqxDropDownList #dropValidado [source]="source"
                      (onChange)="changeValidado($event)" style="float: left" [width]="'100%'" [dropDownHeight]="30*source.length">
                    </jqxDropDownList>
                  </span>

                  <span *ngIf="ecoIdentificadores" style="width: 7%;">
                    <div style="clear: both">
                      {{ translate('Num_tarjetas') }}
                    </div>
                    <jqxInput [value]="ciudadano.tarjetas ? ciudadano.tarjetas.length : 0" [rtl]="true" [width]="'60%'" disabled="true">
                    </jqxInput>
                  </span>
                </div>

                <div class="row">
                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Telefono') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.telefono" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 30%;">
                    <div style="clear: both">
                      {{ translate('Correo_electronico') }} <span *ngIf="ciudadanoEmailExist" style="color: #d40c0c;">{{translate('Correo_existe')}}</span>
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.correoElectronico" [width]="'100%'" (onChange)="checkCiudadano($event)">
                    </jqxInput>
                  </span>

                  <span *ngIf="ecoCompostaje" style="width: 25%;">
                    <div style="margin-top: 5px; clear: both">
                      Tipo
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.tipo" [width]="'100%'" disabled="true">
                    </jqxInput>
                  </span>
                </div>
              </fieldset>
            </div>

            <div #fieldsetDireccion id="fieldsetDireccion">
              <fieldset>
                <legend>{{ translate('Direccion_principal') }}</legend>

                <h3>{{ translate('Direccion_cartografica') }}</h3>

                <div class="row">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Buscar') }}
                    </div>
                    <span #mapSearch>
                      <movisat-map-search style="float: left; width: calc(100% - 30px);"
                        (mapSearch)="onMapSearch($event)">
                      </movisat-map-search>
                      <jqxButton class="button" style="float: left; margin-left: 5px; cursor: pointer;
                        display: flex; align-items: center; justify-content: center;" [width]="25"
                        [height]="25"  [title]="translate('Selecciona_una_ubicacion')"
                        (onClick)="onPosicionar()">
                        <img src="/assets/images/posicion.png" style="height: 20px; width: 20px;">
                      </jqxButton>
                    </span>
                  </span>

                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Domicilio') }}
                    </div>
                    <jqxInput [value]="ciudadano.direccion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 10%;">
                    <div style="clear: both">
                      {{ translate('Numero') }}
                    </div>
                    <jqxInput [value]="ciudadano.numero" [rtl]="true" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 10%;">
                    <div style="clear: both">
                      {{ translate('Codigo_postal') }}
                    </div>
                    <jqxInput [value]="ciudadano.codigoPostal" [rtl]="true" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;">
                    <div style="clear: both">
                      {{ translate('Planta') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.planta" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;">
                    <div style="clear: both">
                      {{ translate('Puerta') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.puerta" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Poblacion') }}
                    </div>
                    <jqxInput [value]="ciudadano.poblacion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                </div>
                <div class="row">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Municipio') }}
                    </div>
                    <jqxInput [value]="ciudadano.municipio" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;">
                    <div style="clear: both;">
                      {{ translate('Provincia') }}
                    </div>
                    <jqxInput [value]="ciudadano.provincia" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                  <span style="width: 25%;">
                    <div style="clear: both;">
                      {{ translate('Comunidad_autonoma') }}
                    </div>
                    <jqxInput [value]="ciudadano.comunidadAutonoma" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                </div>

                <h3>{{ translate('Catastro') }}</h3>
                <app-selector-catastro #selectorCatastro [ciudadano]="ciudadano"></app-selector-catastro>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- PESTAÑA DEL LISTADO DE DIRECCIONES -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-direccion-listado #ciudadanoDirecciones [ciudadano]="ciudadano" (outputDireccion)="asignarDireccion($event)"></app-direccion-listado>
        </div>

        <!-- PESTAÑA DE ASIGNACION DE TARJETAS -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-ciudadanos-tarjetas #ciudadanoTarjetas [ciudadano]="ciudadano" [inputDireccion]="direccion"></app-ciudadanos-tarjetas>
        </div>
      </div>
    </jqxRibbon>
    <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
      (onClick)="onGuardar()" [value]="translate('Guardar')">
    </jqxButton>

    <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
      [value]="translate('CANCELAR')">
    </jqxButton>
  </div>
</jqxWindow>

<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
