<div #elementsContainer id="elemGrid" style="overflow: hidden; height: 100%;">
  <jqxGrid #gridElements [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter"
    [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false" [groupable]="true"
    [rendertoolbar]="createToolBar" [groupsheaderheight]="18" [showcolumnlines]="true" [showtoolbar]="true"
    [columns]="columns" [altrows]="true" [sortmode]="'many'" [enabletooltips]="false" [toolbarheight]="30"
    [filterable]="true" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
    [autoshowfiltericon]="true" [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20" [localization]="langGrid"
    [groupsrenderer]="groupsrenderer" (onCellclick)="onCellClick($event)" (onBindingcomplete)="onBindingComplete()"
    (onFilter)="onChangeFilter($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
  </jqxGrid>
</div>