import { NgModule } from "@angular/core";

import { jqxButtonModule } from "jqwidgets-ng/jqxbuttons";

import { ImagesComponent } from "./images.component";
import { jqxWindowModule } from "jqwidgets-ng/jqxwindow";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [
    ImagesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxWindowModule,
    jqxButtonModule,
  ],
  exports: [
    ImagesComponent
  ]
})
export class ImagesModule { }
