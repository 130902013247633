<div style="width: 100%; display: flex;">
  <jqxDropDownList #cbPeriodo style="float:left; margin-right: 5px;" [width]="110" [height]="height" [theme]="theme"
      [selectedIndex]="0" [autoDropDownHeight]="true" (onSelect)="onSelectPeriodo($event)">
  </jqxDropDownList>
  <div style="display: flex;">
    <div style="display: flex;align-items: center; gap: 5px;">
      <form [formGroup]="dateForm" autocomplete="off">
        <mat-label style="margin-right: 2px">{{translate('Desde')}}</mat-label>
        <mat-form-field fxFlex>
          <input #inputFecha1 matInput [matDatepicker]="fecha1" (dateChange)="change($event, 'desde')" (click)="fecha1.open()"
            formControlName="desde">
          <mat-datepicker-toggle matSuffix [for]="fecha1"></mat-datepicker-toggle>
          <mat-datepicker #fecha1 (yearSelected)="onYearSelected($event, fecha1)"
            (monthSelected)="onMonthSelected($event, fecha1)"></mat-datepicker>
        </mat-form-field>
        <mat-label style="margin-right: 2px;margin-left: 5px ">{{translate('Hasta')}}</mat-label>
        <mat-form-field fxFlex>
          <input #inputFecha2 matInput [matDatepicker]="fecha2" (dateChange)="change($event, 'hasta')" formControlName="hasta">
          <mat-datepicker-toggle matSuffix [for]="fecha2"></mat-datepicker-toggle>
          <mat-datepicker #fecha2 [disabled]="periodoSelec !== 6"></mat-datepicker>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
