<jqxWindow #form [width]="360" [theme]="theme" [height]="300" [zIndex]="100" [autoOpen]="true" [isModal]="false"
    [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow: hidden;">
        {{translate('Itinerarios')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <div style="width: 100%; height: calc(105% - 85px);">
            <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
                [columnsresize]="true" [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'"
                [selectionmode]="'checkbox'" [columns]="columns" [sortable]="true" [altrows]="true"
                [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
                [enabletooltips]="false" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
                [localization]="langGrid" (onRowselect)="onSelect($event)" (onRowunselect)="onSelect($event)">
            </jqxGrid>
        </div>


        <div style="margin-top: 0px; float:left; display: flex;gap: 5px;">
            <div style="float:left; margin-top: 4px;">
                <input [(ngModel)]="visible" type="checkbox" (change)="onChangeVisible($event)" />
                {{translate('Ver')}}
            </div>

            <div style="float:left; margin-top: 4px;">
                <input type="checkbox" (change)="onChangeMarco($event)" />
                {{translate('Ver_marco_geografico')}}
            </div>

        </div>
        <div style="position:absolute; bottom:5px; left:4px;clear: both; display: flex; gap: 5px;">
            <jqxButton style="float: left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar($event)"
                [value]="translate('Guardar')">
            </jqxButton>

            <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="form.close()"
                [value]="translate('CANCELAR')">
            </jqxButton>
        </div>
    </div>
</jqxWindow>