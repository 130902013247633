export class ElemInstalacionEstadoModel {
  FechaCreacion: string;
  FechaModificacion: string;
  FechaBaja: string;
  Id: number;
  IdInstalacion: number;
  GuidInstalacion: string;
  IdEstado: number;
  Fecha: string;
  Empresa: number;

  constructor() { }
}
