import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-direccion-detalles',
  templateUrl: './direccion-detalles.component.html',
  styleUrls: ['./direccion-detalles.component.css']
})
export class DireccionDetallesComponent implements OnInit {
  private componentRef = null;

  public static _this: DireccionDetallesComponent;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public tarjetas = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
    DireccionDetallesComponent._this = this;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, tarjetas: any) {
    this.componentRef = componentRef;
    this.tarjetas = tarjetas;
  }

  ngAfterViewInit() {
    this.initGridDetalle();
  }

  renderMasterColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    try {
      if (value) {
        return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">'+this.translate('Master')+'</div>';
      }
      else{
        return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">'+this.translate('Blanca')+'</div>';
      }
    } catch (error) {

    }
  };

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    try {
      if (value) {
        return '<div class="jqx-grid-cell-middle-align" style="margin-top: 4px; cursor: pointer">V</div>';
      } else {
        return '<div class="jqx-grid-cell-middle-align" style="margin-top: 4px; cursor: pointer">F</div>';
      }
    } catch (error) {

    }
  }

  public columnsTarjetas;
  public sourceTarjetas;
  public dataAdapterTarjetas;

  initGridDetalle() {
    this.columnsTarjetas = [
      { text: this.translate('Modo'), columntype: 'textbox', datafield: 'tipo', width: 50, cellsrenderer: this.renderTipo },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', editable: false, width: 100 },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, width: 140 },
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'master', cellsrenderer: this.renderMasterColumn, width: 100 }
    ];

    this.sourceTarjetas = {
      datatype: 'json',
      datafields: [
        { name: 'tipo', type: 'string', map: 'virtual' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' },
        { name: 'master', type: 'string', map: 'master' }
      ],
      localdata: this.tarjetas
    };

    this.dataAdapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);
  }
}
