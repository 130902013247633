import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { FlotaService } from 'src/app/services/flota/flota.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-subflotas',
  templateUrl: './subflotas.component.html',
  styleUrls: ['./subflotas.component.css']
})
export class SubflotasComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridMoviles1') gridMoviles1: jqxGridComponent;
  @ViewChild('gridMoviles2') gridMoviles2: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;

  public static _this: SubflotasComponent;

  private componentRef = null;
  public environment = environment;
  public subflotas: SubflotaModel[] = [];
  public subflotaSelec: SubflotaModel = null;
  public subflotaEdit: SubflotaModel = null;
  public subflotaResto: SubflotaModel = null;
  public mostrarEditForm = false;
  public canEdit = true;

  // Variables para el grid de subflotas
  public sourceSubflotas: any = [];
  public dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);


  // Variables para el grid de resto de flota
  public movilesResto: MovilModel[] = [];
  public sourceMoviles1: any = [];
  public dataAdapterMoviles1;
  public columnsMoviles1 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total_moviles_resto') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];
  columnsSubflotas: any = [];

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align" style="height: 100%; display: flex;justify-content: flex-end; align-items: center;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  // Variables para el grid de moviles de la subflota
  public movilesSubflota: MovilModel[] = [];
  public sourceMoviles2: any = [];
  public dataAdapterMoviles2;
  public columnsMoviles2 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total_moviles_subflota') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private flotaService: FlotaService,
    private resourcesService: ResourcesService,
    private ssoService: SsoService,
    private modal: NzModalService,
  ) {
    super();
    SubflotasComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Subflotas'));
    // Creo una subflota vacia para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    // Recupero las subflotas
    this.getSubflotas();
    this.initGrid();
  }

  // Este método es llamado por el creador del componente
  public init(componentref: any) {
    this.componentRef = componentref;
  }

  closeWindow(){
    this.form.close();
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  initGrid() {
    this.columnsSubflotas = [
      { text: '', datafield: 'id', hidden: true },
      {
        text: '',
        width: 60,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        resizable: false,
        filterable: false,
        datafield: 'acciones',
        menu: false,
        rendered: (columnHeaderElement) => {
          let time = new Date().getTime();
          const buttonContainer = document.createElement('div');

          const options = {
            width: '100%',
            value: '<i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>',
            textImageRelation: 'imageAboveText',
          };
          buttonContainer.id = `buttonContainerColumn_jqxButton` + time;
          columnHeaderElement[0].appendChild(buttonContainer);
          let myButton;

          setTimeout(() => {
            myButton = jqwidgets.createInstance(
              `#buttonContainerColumn_jqxButton` + time,
              'jqxButton',
              options
            );
            myButton.addEventHandler('click', () => {
              this.onCrearSubflotaClick()
            });
            let btn = <HTMLElement>buttonContainer.parentElement.children[2];
            btn.title = this.translate('Aniadir');
            btn.classList.add('button');
            btn.style.cursor = 'pointer';
            btn.style.height = '25px';
            btn.style.marginTop = '2px';

            let icon = <HTMLElement>btn.children[1];
            icon.style.position = 'unset';
          }, 50);
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      {
        text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 300,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total_subflotas') + ': ' +
              aggregates["Total"] + ' </div>';
          }
          return renderstring;
        }
      },
      {
        text: AppComponent.translate('Moviles'), columntype: 'textbox', filtertype: 'textbox', datafield: 'totMoviles', width: 70, align: 'center',
        cellsalign: 'right', cellsrenderer: this.numberrenderer,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + currentValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      {
        text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 360
      }
    ];

    this.columnsSubflotas.forEach(column => {
      if(column.datafield !== 'acciones') {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      }
    });

    this.sourceSubflotas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'string', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'totMoviles', type: 'number', map: 'MovilesId>length' },
        { name: 'observ', type: 'string', map: 'Observaciones' }
      ],
      localdata: [],
    };
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';


    if(row.boundindex !== undefined){
      this.subflotaSelec = this.subflotas.find(sf => sf.Id === this.grid.getrowdata(row.boundindex).Id);
    }else{
      this.subflotaSelec = this.subflotas.find(sf => sf.Id === this.grid.getrowdata(row).Id);
    }

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Editar')+`">
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      `;
    btnEdit.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.onEditarSubflotaClick()
    });

    htmlElement.appendChild(btnContainer);

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+AppComponent.translate('Borrar')+`">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.id = `buttonDelete_jqxButton`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {
      this.onBorrarSubflotaClick();
    });
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500, ['acciones']);
  }

  async getSubflotas() {
    try {
      this.subflotaSelec = null;
      this.subflotas = await this.flotaService.getSubflotas();
      // Creo la subflota que engloba todos los móviles sin asignar
      if (!this.subflotas) {
        this.subflotas = [];
      }
      await this.getSubflotaResto();
      this.subflotas.push(this.subflotaResto);
      if (this.subflotas) {
        this.sourceSubflotas = {
          datatype: 'json',
          datafields: [
            { name: 'id', type: 'string', map: 'Id' },
            { name: 'nombre', type: 'string', map: 'Nombre' },
            { name: 'totMoviles', type: 'number', map: 'MovilesId>length' },
            { name: 'observ', type: 'string', map: 'Observaciones' }
          ],
          localdata: this.subflotas,
          sortcolumn: 'id',
          sortdirection: 'asc'
        };
        this.dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);
      }
    } catch (e) {
      console.log(e);
    }

    this.resizeColumns(this.grid);
  }

  // Creo la subflota con los móviles no asignados
  async getSubflotaResto(): Promise<void> {
    this.subflotaResto = this.getEmptySubflota();
    this.subflotaResto.Nombre = AppComponent.translate('Resto_flota');
    this.subflotaResto.Observaciones = AppComponent.translate('Moviles_sin_subflota');
    // Recupero todos los móviles
    const moviles = await this.resourcesService.getMoviles();
    // Recupero todos los móviles asignados a alguna subflota
    const movilesAsig = new Map<number, number>();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId) {
          subflota.MovilesId.forEach(movil => {
            movilesAsig.set(movil, movil);
          });
        }
      });
    }
    // Me quedo con los móviles que no están asignados
    if (moviles) {
      moviles.forEach(movil => {
        if (!movilesAsig.get(movil.Codigo)) {
          this.subflotaResto.MovilesId.push(movil.Codigo);
        }
      });
    }
  }

  // Actualiza los móviles de la frota resto después de asignar móviles
  // a una subflota o si se borra una subflota
  async updateSubflotaResto(): Promise<void> {
    // Recupero todos los móviles
    const moviles = await this.resourcesService.getMoviles();
    // Recupero todos los móviles asignados a alguna subflota
    const movilesAsig = new Map<number, number>();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId && subflota.Id > 0) { // Los moviles de la flota resto no cuentan
          subflota.MovilesId.forEach(movil => {
            movilesAsig.set(movil, movil);
          });
        }
      });
    }
    // Me quedo con los móviles que no están asignados
    this.subflotaResto.MovilesId = [];
    if (moviles) {
      moviles.forEach(movil => {
        if (!movilesAsig.get(movil.Codigo)) {
          this.subflotaResto.MovilesId.push(movil.Codigo);
        }
      });
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    SubflotasComponent._this = null;
  }

  // Crea una subflota sin datos
  getEmptySubflota(): SubflotaModel {
    const subflota = new SubflotaModel();
    subflota.Id = 0;
    subflota.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
    subflota.Nombre = '';
    subflota.Observaciones = '';
    subflota.MovilesId = [];
    return subflota;
  }

  onRowSubflotaSelect(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
    this.onEditarSubflotaClick();
  }

  // Crear una nueva subflota
  onCrearSubflotaClick() {
    if (!this.canEdit) {
      return;
    }
    // Creo una subflota vacía para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar la subflota seleccionada
  onEditarSubflotaClick() {
    if (this.subflotaSelec) {
      this.subflotaEdit = { ...this.subflotaSelec };
      if (this.form !== undefined) {
        this.form.collapse();
        this.form.disable();
      }
      this.mostrarEditForm = true;
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Borrar la subflota seleccionada
  onBorrarSubflotaClick() {
    if (!this.canEdit || this.subflotaSelec.Id === 0) {
      return;
    }
    if (this.subflotaSelec !== null) {
      this.deleteSubflota();
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.subflotaEdit.Nombre === null || this.subflotaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    // Recupero los móviles asignados a la subflota
    this.subflotaEdit.MovilesId = [];
    if (this.movilesSubflota.length > 0) {
      this.movilesSubflota.forEach(movil => {
        this.subflotaEdit.MovilesId.push(movil.Codigo);
      });
    }
    this.saveSubflota();
  }

  // Guardo la subflota
  async saveSubflota() {
    // Recupero los móviles no asignados a ninguna subflota
    if (this.movilesResto.length > 0) {
      const sfAux = this.getEmptySubflota();
      sfAux.Id = 0; // Para que se desasignen los móviles
      sfAux.MovilesId = [];
      this, this.movilesResto.forEach(movil => {
        sfAux.MovilesId.push(movil.Codigo);
      });
      // Desasigno los móviles que no están en ninguna subflota
      await this.flotaService.setSubflotaMoviles(sfAux);
    }

    // Almaceno la subflota con todos los móviles asignados
    const result = await this.flotaService.saveSubflota(this.subflotaEdit);

    if (result) {
      if (this.subflotaEdit.Id === 0) {
        this.subflotas.push(result);
      } else {
        for (let i = 0; i < this.subflotas.length; i++) {
          if (this.subflotas[i].Id === this.subflotaEdit.Id) {
            this.subflotas[i] = { ...result };
            break;
          }
        }
      }
      this.subflotaEdit = { ...result };
      this.subflotaSelec = { ...this.subflotaEdit };
      await this.updateSubflotaResto();
      this.grid.updatebounddata('data');
      MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formEdit.close();
  }

  deleteSubflota() {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Borrar_subflota') + ': ' + this.subflotaSelec.Nombre + ' ?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        const result = await this.flotaService.deleteSubflota(this.subflotaSelec.Id);
        if (result) {
          for (let i = 0; i < this.subflotas.length; i++) {
            if (this.subflotas[i].Id === this.subflotaSelec.Id) {
              this.subflotas.splice(i, 1);
              break;
            }
          }
          this.subflotaSelec = null;
          await this.updateSubflotaResto();
          this.grid.updatebounddata('data');
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
        }
      }
    });
  }

  onOpenEditSubflota() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.formEdit.setTitle(AppComponent.translate('Edicion_subflotas'));
      this.ebNombre.focus();
      this.updateListaDisponibles();
      this.addCustomForm(this.formEdit);
    }, 0);
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  async updateListaDisponibles() {
    try {

      // Recupero todos los móviles asignados a alguna subflota
      const otrosMovilesAsig = new Map<number, number>();
      const movilesAsig = new Map<number, number>();
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId) {
          subflota.MovilesId.forEach(movil => {
            if (subflota.Id !== 0) { // Los móviles de la flota resto no cuentan
              if (subflota.Id !== this.subflotaEdit.Id) {
                otrosMovilesAsig.set(movil, movil);
              } else {
                movilesAsig.set(movil, movil);
              }
            }
          });
        }
      });
      // Recupero todos los móviles
      const result = await this.resourcesService.getMoviles()
      if (result) {
        this.movilesResto = [];
        this.movilesSubflota = [];
        result.forEach(movil => {
          if (!movilesAsig.get(movil.Codigo)) {
            if (!otrosMovilesAsig.get(movil.Codigo)) {
              this.movilesResto.push(movil);
            }
          } else {
            this.movilesSubflota.push(movil);
          }
        });
        // Actualizo la lista de móviles sin asignar
        this.sourceMoviles1 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Nombre' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.movilesResto,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles1 = new jqx.dataAdapter(this.sourceMoviles1);

        this.resizeColumns(this.gridMoviles1);
        // Actualizo la lista de móviles asignados
        this.sourceMoviles2 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Nombre' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.movilesSubflota,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles2 = new jqx.dataAdapter(this.sourceMoviles2);

        this.resizeColumns(this.gridMoviles2);
      }
    } finally {

    }
  }

  onMasClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles1.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesSubflota.push(this.movilesResto[rowIndex]);
        moviles.set(this.movilesResto[rowIndex].Codigo, this.movilesResto[rowIndex].Codigo);
      });
      for (let i = this.movilesResto.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesResto[i].Codigo)) {
          this.movilesResto.splice(i, 1);
        }
      };
      this.gridMoviles1.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

  onMenosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles2.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesResto.push(this.movilesSubflota[rowIndex]);
        moviles.set(this.movilesSubflota[rowIndex].Codigo, this.movilesSubflota[rowIndex].Codigo);
      });
      for (let i = this.movilesSubflota.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesSubflota[i].Codigo)) {
          this.movilesSubflota.splice(i, 1);
        }
      };
      this.gridMoviles2.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

}
