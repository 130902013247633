import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSchedulerComponent } from 'jqwidgets-ng/jqxscheduler';

import { CalendarService } from 'src/app/services/calendar/calendar.service';

import { Appointment } from '../appointment.model';
import { CalendarModel } from 'src/app/services/calendar/models/calendar.model';

import { CalendarComponent } from '../calendar.component';
import { SsoService } from 'src/app/services/sso/sso.service';

@Component({
  selector: 'app-view-calendar',
  templateUrl: './view-calendar.component.html',
  styleUrls: ['./view-calendar.component.css']
})
export class ViewCalendarComponent extends CustomForms implements OnInit {
  @ViewChild('viewCalendar') viewCalendar: jqxWindowComponent;
  @ViewChild('schedulerReference') scheduler: jqxSchedulerComponent;

  private componentRef = null;
  static _this: any;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  appointments: any = [];
  calendarios: CalendarModel[] = [];
  views = ['dayView', 'weekView', 'monthView'];
  row: number;
  date: any = new jqx.date();

  constructor(private calendarService: CalendarService, private ssoService: SsoService) {
    super();
    ViewCalendarComponent._this = this;
  }

  ngOnInit() {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    //this.getSources();
  }

  /**Inicializa el componente
  */
  init(componentRef: any, calendarios: CalendarModel[], row: number) {
    this.componentRef = componentRef;
    this.calendarios = calendarios;
    this.row = row;
  }

  ngAfterViewInit() {
    this.viewCalendar.setTitle(AppComponent.translate('Calendarios'));
    this.addCustomForm(this.viewCalendar);
  }

  closeWindow() {
    this.viewCalendar.close();
  }

  resources = this.getResources();
  // componente scheduler
  generateAppointments(): Appointment[] {
    this.calendarios = CalendarComponent._this.calendarios;
    this.row = CalendarComponent._this.row;
    // filtrar por el id de la fila seleccionada
    this.calendarios = this.calendarios.filter(calendario => calendario.id == this.row);
    this.calendarios.forEach((calendario, i) => {
      calendario.calendarId = 'Calendario ' + (i + 1);
      calendario.calendario.forEach((calendarioHour, index) => {
        let appointment: Appointment = {
          Id: calendario.id,
          Description: calendario.observaciones,
          Location: "",
          Subject: calendario.nombre,
          Calendar: calendario.calendarId,
          Start: calendarioHour['horaInicio'],
          End: calendarioHour['horaFin'],
          Status: 'free',
          Calendario: calendario.calendario,
          Allday: calendario.todosLosDias
        };
        this.appointments.push(appointment);
      });
    });
    return this.appointments;
  };

  source: any =
  {
    dataType: "array",
    dataFields: [
      { name: 'id', type: 'string', map: 'Id' },
      { name: 'description', type: 'string', map: 'Description' },
      { name: 'location', type: 'string', map: 'Location' },
      { name: 'subject', type: 'string', map: 'Subject' },
      { name: 'calendar', type: 'string', map: 'Calendar' },
      { name: 'start', type: 'date', map: 'Start' },
      { name: 'end', type: 'date', map: 'End' },
      { name: 'status', type: 'string', map: 'Status' },
      { name: 'AllDay', type: 'boolean', map: 'Allday' }
    ],
    id: 'id',
    localData: this.generateAppointments()
  };
  dataAdapter: any = new jqx.dataAdapter(this.source);

  appointmentDataFields: any =
  {
    from: "start",
    to: "end",
    id: "id",
    description: "description",
    location: "location",
    subject: "subject",
    style: "modern",
    resourceId: "calendar",
    status: 'status',
    allDay: 'AllDay',
    draggable: false
  };

  ViewChange(event: any): void {
    if (event.args.type == 'monthView') {
      this.scheduler.ensureAppointmentVisible('id1');
    }
  }

  // funcion para obtener los recursos de los calendarios
  getResources() {
    this.calendarios = CalendarComponent._this.calendarios;
    this.row = CalendarComponent._this.row;
    let calendar = this.calendarios.filter(appointment => appointment.id == this.row);
    this.resources = {
      colorScheme: "scheme05",
      dataField: "calendar",
      source: [
        {
          calendar: calendar[0].nombre,
          color: this.getRandomColor()
        },
      ]
    };
    return this.resources;
  }

  // funcion para cambiar de color aleatoriamente
  getRandomColor(): string {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
      CalendarComponent._this.form.expand();
    }
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
