export class ElemInstalacionDetalleImagenModel {
  Imagen: string;
  Empresa: number;
  Fecha: string;
  FechaBaja: string;
  FechaCreacion: string;
  FechaModificacion: string;
  Guid: string;
  GuidInstalacionDetalle: string;
  Id: number;
  IdInstalacionDetalle: number;

  constructor() { }
}
